/*
 SP inquiry お問い合わせ
---------------------------------------- */

.required {
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	height: 18px;
	background-color: #E50012;
	text-align: center;
	line-height: 15px;
	border-radius: 3px;
	margin: 0 5px;
	&::before {
		content: "必須";
		@include fs(12);
		color: #FFF;
	}
}

.inquiry-page {
	.container {
		margin: 0 0 50px 0;
	}
	.inquiry-form-box {
		margin: 30px auto 0;
		width: 100%;
		height: auto;
		padding: 0 5%;
		.inquiry-attention {
			width: 100%;
			height: auto;
			border: solid 1px #B5B5B6;
			padding: 15px 20px;
			box-sizing: border-box;
			p {
				vertical-align: middle;
				@include fs(14);
				color: #333;
			}
		}
		.inquiry-form {
			margin: 50px auto 0;
			width: 100%;
			height: auto;
			table {
				width: 100%;
				tr {
					display: block;
					margin: 10px 0 0 0;
				}
				th {
					display: block;
					width: auto;
					height: auto;
					background-color: none;
					padding: 0;
					box-sizing: border-box;
					border: none;
					@include fs(14);
				}
				td {
					margin: 5px 0 0 0;
					display: block;
					padding: 0;
					box-sizing: border-box;
					border: none;
					@include fs(14);
					input,
					textarea {
						width: 100%;
						height: 34px;
						border-radius: 5px;
						border: solid 1px #B5B5B6;
					}
					textarea {
						height: 180px;
					}
				}
			}
			.confirmation-wrapper {
				margin: 40px auto 0;
				width: 100%;
				height: auto;
				.personal-information {
					margin: 15px 0 0 0;
					width: 100%;
					height: 250px;
					border: solid 1px #9E9E9F;
					padding: 10px 20px;
					box-sizing: border-box;
					overflow-y: scroll;
				}
				.personal-information-check {
					margin: 65px auto 0;
					width: 100%;
					height: auto;
					text-align: center;
					font-size: 0;
					input[type="checkbox"] {
						display: inline-block;
						vertical-align: top;
						position: relative;
						top: 4px;
						left: 0;
						&:hover {
							cursor: pointer;
						}
					}
					label {
            width: 92%;
						display: inline-block;
						text-align: left;
						@include fs(14);
						color: #333;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.inquiry-button {
					display: block;
					margin: 40px auto 0;
					@include fs(20);
					button:hover {
						cursor: pointer;
					}
				}
				.about-holiday {
					margin: 70px 0 0 0;
					@include fs(14);
				}
				.contact-info {
					margin: 50px 0 0 0;
					@include fs(14);
				}
			}
		}
	}
}
.features-page {
	.container {
		margin: 0;
	}
	.features {
		position: relative;
		overflow: hidden;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -15%;
			width: 180%;
			height: 90%;
			margin: 0 -10%;
			background: #e0c11d;
			background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
			background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
			background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
			-webkit-transform-origin: right center;
			-ms-transform-origin: right center;
			transform-origin: right center;
			-webkit-transform: rotate(-4deg);
			-ms-transform: rotate(-4deg);
			transform: rotate(-4deg);
			z-index: -1;
			opacity: 0.3;
		}
		.feature-list {
			width: 100%;
			height: auto;
			padding: 0 5%;
			.feature-colmun {
				margin: 65px 0 0 0;
				&:first-child {
					margin: 0;
				}
				width: 100%;
				height: auto;
				.content {
					margin: 40px 0 0 0;
					font-size: 0;
					img {
						display: inline-block;
						vertical-align: top;
						width: 100%;
						height: auto;
					}
					p {
						margin: 20px 0 0 0;
						display: inline-block;
						vertical-align: top;
						width: 100%;
						@include fs(14);
					}
				}
			}
		}
	}
}
/*
 PC personal-information 個人情報
---------------------------------------- */

.personal-information-page {
	.about-personal-information {
		margin: 95px auto 0;
		width: 1000px;
		.content-wrapper {
			width: 100%;
			height: auto;
			padding: 55px 50px;
			box-sizing: border-box;
			.sub-title {
				margin: 40px 0 0 0;
				&:first-child {
					margin: 0;
				}
				@include fs(16);
				color: #00913A;
			}
			.content {
				@include fs(16);
				color: #333;
			}
		}
		.privacy-mark-wrapper {
			margin: 60px 0 115px 0;
			width: 100%;
      padding-left: 50px;
			height: auto;
			.privacy-mark-link {
				display: inline-block;
				vertical-align: top;
				width: 100px;
				height: auto;
				.privacy-mark-img {
					width: 100%;
					height: auto;
				}
			}
			.description {
				display: inline-block;
				vertical-align: top;
				width: 657px;
				height: auto;
				text-align: left;
				margin: 0 0 0 10px;
			}
		}
	}
}
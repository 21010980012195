/* ========== tit ========== */

.min_tit {
  @include fs(28);
  text-align: center;
  padding-top: 30px;
  &:after {
    margin: 20px auto;
    content: '';
    display: block;
    text-align: center;
    width: 32px;
    height: 1px;
    background: $clrBase;
  }
}

.sec_tit {
  width: 540px;
  text-align: center;
  margin: 0 auto;
  .en {
    @include fs(70);
    font-weight: 100;
    font-family: $fontFamilyRaleway;
    display: block;
    line-height: 1.4;
    &:after {
      margin: 10px auto;
      content: '';
      display: block;
      text-align: center;
      width: 32px;
      height: 1px;
      background: $clrBase;
    }
  }
  .jp {
    @include fs(24);
    display: block;
    padding-bottom: 50px;
  }
  &.add-shape {
    background-image: url(../img/title-shape.png);
    background-repeat: no-repeat;
  }
}

.tit--green {
  // 緑の文字のタイトル
  text-align: center;
  .en {
    display: block;
    @include fs(56);
    line-height: 1.3;
    font-weight: 100;
    color: $clrGrn;
    font-family: $fontFamilyRaleway;
  }
  .jp {
    display: block;
    @include fs(22);
    color: #FFF;
    background-color: #00913A;
    width: 260px;
    margin: 0 auto;
    line-height: 30px;
    &::before {
      content: "";
      display: block;
      width: 1000px;
      height: 1px;
      background-color: #00913A;
      position: relative;
      top: 15px;
      left: -370px;
      z-index: -1;
    }
  }
}

.tit--leftborder {
  width: 100%;
  height: auto;
  padding: 10px 30px;
  background-color: #F7F8F8;
  border-left: solid 6px #00913A;
  @include fs(24);
  color: #333;
  line-height: 1;
}

/* ========== btn ========== */

.btn_default {
  width: 288px;
  margin: 0 auto;
  a,
  button,
  input[type="submit"],
  input[type="button"]{
    display: block;
    background: $clrGrn url(../img/ico_arw.svg) no-repeat 90% center;
    border-radius: 5px;
    line-height: 50px;
    color: $clrWht;
    @include fs(20);
    font-family: $fontFamily;
    text-align: center;
    border: none;
    border-bottom: 5px $clrGrn03 solid;
    width: 100%;
  }
  button:disabled {
    background: $clrBorder url(../img/ico_arw.svg) no-repeat 90% center;
    border-bottom: 5px #888 solid;
    cursor: default;
  }
}

.btn_min {
  width: 154px;
  a {
    display: block;
    background: $clrGrn;
    color: $clrWht;
    border-radius: 3px;
    text-align: center;
  }
}
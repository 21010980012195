/*
 PC access アクセス
---------------------------------------- */

.access-page {
	.container {
		margin: 0 0 70px 0;
	}
	.map-image-wrapper {
		width: 495px;
		height: 495px;
		margin: 95px auto;
	}
	section.location-info {
		.access-info-list {
			display: block;
			width: 1000px;
			height: auto;
			margin: 100px auto 0;
			.access-info-item {
				width: 1000px;
				height: auto;
				font-size: 0;
				margin: 25px 0 0 0;
				&:first-child {
					margin: 0;
				}
				img {
					display: inline-block;
					vertical-align: top;
					width: 450px;
					height: 280px;
				}
				.text-wrapper {
					display: inline-block;
					vertical-align: top;
					width: 550px;
					height: auto;
					padding: 0 0 0 40px;
					box-sizing: border-box;
					line-height: 1;
					.title {
						@include fs(24);
						color: #00913A;
					}
					.content {
						@include fs(16);
						color: #333;
						margin: 25px 0 0 0;
						line-height: 31px;
					}
				}
			}
		}
		.googlemap-wrapper {
			width: 1000px;
			height: auto;
			margin: 90px auto 0;
			.see-in-google-map {
				@include fs(24);
				color: #00913A;
			}
			.googlemap-content {
				margin: 25px 0 0 0;
				width: 100%;
				height: 540px;
				background-color: #FDF6ED;
				position: relative;
				iframe,
				object,
				embed {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
/*
  base
--------------------------------------------- */

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	min-width: $widthMax;
	@include fs(16);
	font-family: $fontFamily;
	font-weight: normal;
	line-height: $lhBase;
  text-align: justify;
	color: $clrBase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@media #{$querySp} {
		@include fs(14);
		line-height: $lhBaseSp;
		min-width: inherit;
	}
}

img {
  border-style: none;
  display: block;
  vertical-align: bottom;
  @media #{$querySp} {
    max-width: 100%;
  }
}

a,
a img,
button {
  transition: .4s;
}

a img:hover {
  opacity: .7;
}

a:link {
  color: $clrLink;
  text-decoration: none;
}

a:visited {
  color: $clrLink;
}

a:hover {
  color: $clrLinkHover;
  opacity: .7;
  transition: .4s;
}

a:active {
  color: $clrLinkHover;
}

p.sys {
  display: none;
}
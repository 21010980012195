/*
 SP personal-information 個人情報
---------------------------------------- */

.personal-information-page {
	.container {
		margin: 0;
	}
	.about-personal-information {
		margin: 30px auto 0;
		width: 100%;
		padding: 0 5%;
		.content-wrapper {
			width: 100%;
			height: auto;
			padding: 25px 0;
			box-sizing: border-box;
			.sub-title {
				margin: 40px 0 0 0;
				&:first-child {
					margin: 0;
				}
				@include fs(14);
				color: #00913A;
			}
			.content {
				@include fs(14);
				color: #333;
			}
		}
		.privacy-mark-wrapper {
			margin: 30px 0 60px 0;
			width: 100%;
			height: auto;
			text-align: right;
			font-size: 0;
			.privacy-mark-link {
				display: inline-block;
				vertical-align: top;
				width: 100px;
				height: auto;
				.privacy-mark-img {
					width: 100%;
					height: auto;
				}
			}
			.description {
				display: inline-block;
				vertical-align: top;
				width: calc(100% - 110px);
				height: auto;
				text-align: left;
				margin: 0 0 0 10px;
				@include fs(14);
			}
		}
	}
}
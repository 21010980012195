/*
 SP instructor 講師紹介
---------------------------------------- */

.instructor-page {
	.container {
		margin: 0;
	}
	.instructor {
		position: relative;
		display: block;
		.instructor-list {
			width: 100%;
			height: auto;
			margin: 60px auto 0;
			padding: 0 5%;
			.instructor-item {
				display: block;
				width: 100%;
				height: auto;
				padding: 20px 8% 30px;
				box-sizing: border-box;
				font-size: 0;
				background-color: #fff;
				position: relative;
				margin: 40px 0 0 0;
				&:first-child {
					margin: 0;
				}
				&::after {
					content: '\00a0';
					display: block;
					position: absolute;
					top: -3px;
					left: -3px;
					z-index: -1;
					width: calc(100% + 6px);
					height: calc(100% + 6px);
					background: #e0c11d;
					background: -moz-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
					background: -webkit-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
					background: linear-gradient(135deg, #e0c11d 0%, #00913a 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=1);
				}
				.instructor-image-wrapper {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					height: auto;
					text-align: center;
					img {
						display: inline-block;
						vertical-align: top;
						.large {
							width: 240px;
							height: 340px;
						}
						.small {
							width: 150px;
							height: 340px;
						}
					}
				}
				.instructor-text-wrapper {
					margin: 20px 0 0 0;
					display: inline-block;
					vertical-align: top;
					width: 100%;
					height: auto;
					text-align: left;
					.instructor-name {
						@include fs(18);
						color: #333;
						width: 100%;
						border: solid #333;
						border-width: 0 0 1px 0;
					}
					.instructor-affiliation {
						margin: 15px 0 0 0;
						@include fs(15);
						color: #00913A;
					}
					.instructor-field {
						margin: 25px 0 0 0;
						@include fs(15);
						color: #333;
					}
					.instructor-description {
						margin: 20px 0 0 0;
						@include fs(14);
						color: #333;
					}
				}
			}
		}
	}
}
// ----- fonts

@import url('https://fonts.googleapis.com/earlyaccess/notosansjapanese.css');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,400');

$fontFamily: 'Noto Sans Japanese', sans-serif;

$fontFamilyNotoSerif: 'Noto Serif', serif;
$fontFamilyRaleway: 'Raleway', sans-serif;

// ----- line-height

$lhBase: 2;
$lhBaseSp: 2;

// ----- colors

$clrBase: #333;
$clrLink: #333;
$clrLinkHover: #333;

$clrBlk: #000; // サイトの黒を定義
$clrWht: #fff; // サイトの白を定義
$clrGry: #595757; //サイトの灰色を定義

$clrYlw: #e0c21b;
$clrYlw02: #f2e003;
$clrYlw03: #a38a0d;
$clrGrn: #00913a;
$clrGrn02: #d6eae2;
$clrGrn03: #006934;
$clrRed: #e33315;
$clrOrg: #ea5514;
$clrOrg02: #ad380e;

$clrBorder: #c9caca;

// margin

$mgSmall: 20;
$mgNormal: 30;
$mgLarge: 60;
$mgContainer: 40;

// ----- layout

$widthBase: 1000px;
$widthMax: 1130px;

$queryPc: "screen and (min-width: 768px)"; //PC
$querySp: "screen and (max-width: 767px)"; //SP
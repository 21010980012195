@charset "UTF-8";
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,400");
/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

input, textarea {
  font-family: "Noto Sans Japanese", sans-serif; }

/*
  base
--------------------------------------------- */
html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

body {
  width: 100%;
  height: 100%;
  min-width: 1130px;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: normal;
  line-height: 2;
  text-align: justify;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 2;
      min-width: inherit; } }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }
  @media screen and (max-width: 767px) {
    img {
      max-width: 100%; } }

a,
a img,
button {
  transition: .4s; }

a img:hover {
  opacity: .7; }

a:link {
  color: #333;
  text-decoration: none; }

a:visited {
  color: #333; }

a:hover {
  color: #333;
  opacity: .7;
  transition: .4s; }

a:active {
  color: #333; }

p.sys {
  display: none; }

/*
  common
--------------------------------------------- */
@media screen and (max-width: 767px) {
  .sp_only {
    display: block; } }

@media screen and (min-width: 768px) {
  .sp_only {
    display: none; } }

@media screen and (min-width: 768px) {
  .pc_only {
    display: block; } }

@media screen and (max-width: 767px) {
  .pc_only {
    display: none; } }

/* float
______________________________________________*/
.fl_r {
  float: right; }

.fl_l {
  float: left; }

/* clearfix
______________________________________________*/
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/* align
______________________________________________*/
.ta_l {
  text-align: left !important; }

.ta_c {
  text-align: center !important; }

.ta_r {
  text-align: right !important; }

.va_t {
  vertical-align: top !important; }

.va_m {
  vertical-align: middle !important; }

.va_b {
  vertical-align: bottom !important; }

/* margin
______________________________________________*/
.mt_100 {
  margin-top: 100px !important; }

.mb_100 {
  margin-bottom: 100px !important; }

.mr_100 {
  margin-right: 100px !important; }

.ml_100 {
  margin-left: 100px !important; }

.mt_95 {
  margin-top: 95px !important; }

.mb_95 {
  margin-bottom: 95px !important; }

.mr_95 {
  margin-right: 95px !important; }

.ml_95 {
  margin-left: 95px !important; }

.mt_90 {
  margin-top: 90px !important; }

.mb_90 {
  margin-bottom: 90px !important; }

.mr_90 {
  margin-right: 90px !important; }

.ml_90 {
  margin-left: 90px !important; }

.mt_85 {
  margin-top: 85px !important; }

.mb_85 {
  margin-bottom: 85px !important; }

.mr_85 {
  margin-right: 85px !important; }

.ml_85 {
  margin-left: 85px !important; }

.mt_80 {
  margin-top: 80px !important; }

.mb_80 {
  margin-bottom: 80px !important; }

.mr_80 {
  margin-right: 80px !important; }

.ml_80 {
  margin-left: 80px !important; }

.mt_75 {
  margin-top: 75px !important; }

.mb_75 {
  margin-bottom: 75px !important; }

.mr_75 {
  margin-right: 75px !important; }

.ml_75 {
  margin-left: 75px !important; }

.mt_70 {
  margin-top: 70px !important; }

.mb_70 {
  margin-bottom: 70px !important; }

.mr_70 {
  margin-right: 70px !important; }

.ml_70 {
  margin-left: 70px !important; }

.mt_65 {
  margin-top: 65px !important; }

.mb_65 {
  margin-bottom: 65px !important; }

.mr_65 {
  margin-right: 65px !important; }

.ml_65 {
  margin-left: 65px !important; }

.mt_60 {
  margin-top: 60px !important; }

.mb_60 {
  margin-bottom: 60px !important; }

.mr_60 {
  margin-right: 60px !important; }

.ml_60 {
  margin-left: 60px !important; }

.mt_55 {
  margin-top: 55px !important; }

.mb_55 {
  margin-bottom: 55px !important; }

.mr_55 {
  margin-right: 55px !important; }

.ml_55 {
  margin-left: 55px !important; }

.mt_50 {
  margin-top: 50px !important; }

.mb_50 {
  margin-bottom: 50px !important; }

.mr_50 {
  margin-right: 50px !important; }

.ml_50 {
  margin-left: 50px !important; }

.mt_45 {
  margin-top: 45px !important; }

.mb_45 {
  margin-bottom: 45px !important; }

.mr_45 {
  margin-right: 45px !important; }

.ml_45 {
  margin-left: 45px !important; }

.mt_40 {
  margin-top: 40px !important; }

.mb_40 {
  margin-bottom: 40px !important; }

.mr_40 {
  margin-right: 40px !important; }

.ml_40 {
  margin-left: 40px !important; }

.mt_35 {
  margin-top: 35px !important; }

.mb_35 {
  margin-bottom: 35px !important; }

.mr_35 {
  margin-right: 35px !important; }

.ml_35 {
  margin-left: 35px !important; }

.mt_30 {
  margin-top: 30px !important; }

.mb_30 {
  margin-bottom: 30px !important; }

.mr_30 {
  margin-right: 30px !important; }

.ml_30 {
  margin-left: 30px !important; }

.mt_25 {
  margin-top: 25px !important; }

.mb_25 {
  margin-bottom: 25px !important; }

.mr_25 {
  margin-right: 25px !important; }

.ml_25 {
  margin-left: 25px !important; }

.mt_20 {
  margin-top: 20px !important; }

.mb_20 {
  margin-bottom: 20px !important; }

.mr_20 {
  margin-right: 20px !important; }

.ml_20 {
  margin-left: 20px !important; }

.mt_15 {
  margin-top: 15px !important; }

.mb_15 {
  margin-bottom: 15px !important; }

.mr_15 {
  margin-right: 15px !important; }

.ml_15 {
  margin-left: 15px !important; }

.mt_10 {
  margin-top: 10px !important; }

.mb_10 {
  margin-bottom: 10px !important; }

.mr_10 {
  margin-right: 10px !important; }

.ml_10 {
  margin-left: 10px !important; }

.mt_5 {
  margin-top: 5px !important; }

.mb_5 {
  margin-bottom: 5px !important; }

.mr_5 {
  margin-right: 5px !important; }

.ml_5 {
  margin-left: 5px !important; }

.mt_0 {
  margin-top: 0px !important; }

.mb_0 {
  margin-bottom: 0px !important; }

.mr_0 {
  margin-right: 0px !important; }

.ml_0 {
  margin-left: 0px !important; }

/* Padding
______________________________________________*/
.pt_100 {
  padding-top: 100px !important; }

.pb_100 {
  padding-bottom: 100px !important; }

.pr_100 {
  padding-right: 100px !important; }

.pl_100 {
  padding-left: 100px !important; }

.p_100 {
  padding: 100px !important; }

.pt_95 {
  padding-top: 95px !important; }

.pb_95 {
  padding-bottom: 95px !important; }

.pr_95 {
  padding-right: 95px !important; }

.pl_95 {
  padding-left: 95px !important; }

.p_95 {
  padding: 95px !important; }

.pt_90 {
  padding-top: 90px !important; }

.pb_90 {
  padding-bottom: 90px !important; }

.pr_90 {
  padding-right: 90px !important; }

.pl_90 {
  padding-left: 90px !important; }

.p_90 {
  padding: 90px !important; }

.pt_85 {
  padding-top: 85px !important; }

.pb_85 {
  padding-bottom: 85px !important; }

.pr_85 {
  padding-right: 85px !important; }

.pl_85 {
  padding-left: 85px !important; }

.p_85 {
  padding: 85px !important; }

.pt_80 {
  padding-top: 80px !important; }

.pb_80 {
  padding-bottom: 80px !important; }

.pr_80 {
  padding-right: 80px !important; }

.pl_80 {
  padding-left: 80px !important; }

.p_80 {
  padding: 80px !important; }

.pt_75 {
  padding-top: 75px !important; }

.pb_75 {
  padding-bottom: 75px !important; }

.pr_75 {
  padding-right: 75px !important; }

.pl_75 {
  padding-left: 75px !important; }

.p_75 {
  padding: 75px !important; }

.pt_70 {
  padding-top: 70px !important; }

.pb_70 {
  padding-bottom: 70px !important; }

.pr_70 {
  padding-right: 70px !important; }

.pl_70 {
  padding-left: 70px !important; }

.p_70 {
  padding: 70px !important; }

.pt_65 {
  padding-top: 65px !important; }

.pb_65 {
  padding-bottom: 65px !important; }

.pr_65 {
  padding-right: 65px !important; }

.pl_65 {
  padding-left: 65px !important; }

.p_65 {
  padding: 65px !important; }

.pt_60 {
  padding-top: 60px !important; }

.pb_60 {
  padding-bottom: 60px !important; }

.pr_60 {
  padding-right: 60px !important; }

.pl_60 {
  padding-left: 60px !important; }

.p_60 {
  padding: 60px !important; }

.pt_55 {
  padding-top: 55px !important; }

.pb_55 {
  padding-bottom: 55px !important; }

.pr_55 {
  padding-right: 55px !important; }

.pl_55 {
  padding-left: 55px !important; }

.p_55 {
  padding: 55px !important; }

.pt_50 {
  padding-top: 50px !important; }

.pb_50 {
  padding-bottom: 50px !important; }

.pr_50 {
  padding-right: 50px !important; }

.pl_50 {
  padding-left: 50px !important; }

.p_50 {
  padding: 50px !important; }

.pt_45 {
  padding-top: 45px !important; }

.pb_45 {
  padding-bottom: 45px !important; }

.pr_45 {
  padding-right: 45px !important; }

.pl_45 {
  padding-left: 45px !important; }

.p_45 {
  padding: 45px !important; }

.pt_40 {
  padding-top: 40px !important; }

.pb_40 {
  padding-bottom: 40px !important; }

.pr_40 {
  padding-right: 40px !important; }

.pl_40 {
  padding-left: 40px !important; }

.p_40 {
  padding: 40px !important; }

.pt_35 {
  padding-top: 35px !important; }

.pb_35 {
  padding-bottom: 35px !important; }

.pr_35 {
  padding-right: 35px !important; }

.pl_35 {
  padding-left: 35px !important; }

.p_35 {
  padding: 35px !important; }

.pt_30 {
  padding-top: 30px !important; }

.pb_30 {
  padding-bottom: 30px !important; }

.pr_30 {
  padding-right: 30px !important; }

.pl_30 {
  padding-left: 30px !important; }

.p_30 {
  padding: 30px !important; }

.pt_25 {
  padding-top: 25px !important; }

.pb_25 {
  padding-bottom: 25px !important; }

.pr_25 {
  padding-right: 25px !important; }

.pl_25 {
  padding-left: 25px !important; }

.p_25 {
  padding: 25px !important; }

.pt_20 {
  padding-top: 20px !important; }

.pb_20 {
  padding-bottom: 20px !important; }

.pr_20 {
  padding-right: 20px !important; }

.pl_20 {
  padding-left: 20px !important; }

.p_20 {
  padding: 20px !important; }

.pt_15 {
  padding-top: 15px !important; }

.pb_15 {
  padding-bottom: 15px !important; }

.pr_15 {
  padding-right: 15px !important; }

.pl_15 {
  padding-left: 15px !important; }

.p_15 {
  padding: 15px !important; }

.pt_10 {
  padding-top: 10px !important; }

.pb_10 {
  padding-bottom: 10px !important; }

.pr_10 {
  padding-right: 10px !important; }

.pl_10 {
  padding-left: 10px !important; }

.p_10 {
  padding: 10px !important; }

.pt_5 {
  padding-top: 5px !important; }

.pb_5 {
  padding-bottom: 5px !important; }

.pr_5 {
  padding-right: 5px !important; }

.pl_5 {
  padding-left: 5px !important; }

.p_5 {
  padding: 5px !important; }

.pt_0 {
  padding-top: 0px !important; }

.pb_0 {
  padding-bottom: 0px !important; }

.pr_0 {
  padding-right: 0px !important; }

.pl_0 {
  padding-left: 0px !important; }

.p_0 {
  padding: 0px !important; }

@media screen and (max-width: 767px) {
  .pt_100--sp {
    padding-top: 100px !important; } }

@media screen and (max-width: 767px) {
  .pb_100--sp {
    padding-bottom: 100px !important; } }

@media screen and (max-width: 767px) {
  .pr_100--sp {
    padding-right: 100px !important; } }

@media screen and (max-width: 767px) {
  .pl_100--sp {
    padding-left: 100px !important; } }

@media screen and (max-width: 767px) {
  .p_100--sp {
    padding: 100px !important; } }

@media screen and (max-width: 767px) {
  .pt_95--sp {
    padding-top: 95px !important; } }

@media screen and (max-width: 767px) {
  .pb_95--sp {
    padding-bottom: 95px !important; } }

@media screen and (max-width: 767px) {
  .pr_95--sp {
    padding-right: 95px !important; } }

@media screen and (max-width: 767px) {
  .pl_95--sp {
    padding-left: 95px !important; } }

@media screen and (max-width: 767px) {
  .p_95--sp {
    padding: 95px !important; } }

@media screen and (max-width: 767px) {
  .pt_90--sp {
    padding-top: 90px !important; } }

@media screen and (max-width: 767px) {
  .pb_90--sp {
    padding-bottom: 90px !important; } }

@media screen and (max-width: 767px) {
  .pr_90--sp {
    padding-right: 90px !important; } }

@media screen and (max-width: 767px) {
  .pl_90--sp {
    padding-left: 90px !important; } }

@media screen and (max-width: 767px) {
  .p_90--sp {
    padding: 90px !important; } }

@media screen and (max-width: 767px) {
  .pt_85--sp {
    padding-top: 85px !important; } }

@media screen and (max-width: 767px) {
  .pb_85--sp {
    padding-bottom: 85px !important; } }

@media screen and (max-width: 767px) {
  .pr_85--sp {
    padding-right: 85px !important; } }

@media screen and (max-width: 767px) {
  .pl_85--sp {
    padding-left: 85px !important; } }

@media screen and (max-width: 767px) {
  .p_85--sp {
    padding: 85px !important; } }

@media screen and (max-width: 767px) {
  .pt_80--sp {
    padding-top: 80px !important; } }

@media screen and (max-width: 767px) {
  .pb_80--sp {
    padding-bottom: 80px !important; } }

@media screen and (max-width: 767px) {
  .pr_80--sp {
    padding-right: 80px !important; } }

@media screen and (max-width: 767px) {
  .pl_80--sp {
    padding-left: 80px !important; } }

@media screen and (max-width: 767px) {
  .p_80--sp {
    padding: 80px !important; } }

@media screen and (max-width: 767px) {
  .pt_75--sp {
    padding-top: 75px !important; } }

@media screen and (max-width: 767px) {
  .pb_75--sp {
    padding-bottom: 75px !important; } }

@media screen and (max-width: 767px) {
  .pr_75--sp {
    padding-right: 75px !important; } }

@media screen and (max-width: 767px) {
  .pl_75--sp {
    padding-left: 75px !important; } }

@media screen and (max-width: 767px) {
  .p_75--sp {
    padding: 75px !important; } }

@media screen and (max-width: 767px) {
  .pt_70--sp {
    padding-top: 70px !important; } }

@media screen and (max-width: 767px) {
  .pb_70--sp {
    padding-bottom: 70px !important; } }

@media screen and (max-width: 767px) {
  .pr_70--sp {
    padding-right: 70px !important; } }

@media screen and (max-width: 767px) {
  .pl_70--sp {
    padding-left: 70px !important; } }

@media screen and (max-width: 767px) {
  .p_70--sp {
    padding: 70px !important; } }

@media screen and (max-width: 767px) {
  .pt_65--sp {
    padding-top: 65px !important; } }

@media screen and (max-width: 767px) {
  .pb_65--sp {
    padding-bottom: 65px !important; } }

@media screen and (max-width: 767px) {
  .pr_65--sp {
    padding-right: 65px !important; } }

@media screen and (max-width: 767px) {
  .pl_65--sp {
    padding-left: 65px !important; } }

@media screen and (max-width: 767px) {
  .p_65--sp {
    padding: 65px !important; } }

@media screen and (max-width: 767px) {
  .pt_60--sp {
    padding-top: 60px !important; } }

@media screen and (max-width: 767px) {
  .pb_60--sp {
    padding-bottom: 60px !important; } }

@media screen and (max-width: 767px) {
  .pr_60--sp {
    padding-right: 60px !important; } }

@media screen and (max-width: 767px) {
  .pl_60--sp {
    padding-left: 60px !important; } }

@media screen and (max-width: 767px) {
  .p_60--sp {
    padding: 60px !important; } }

@media screen and (max-width: 767px) {
  .pt_55--sp {
    padding-top: 55px !important; } }

@media screen and (max-width: 767px) {
  .pb_55--sp {
    padding-bottom: 55px !important; } }

@media screen and (max-width: 767px) {
  .pr_55--sp {
    padding-right: 55px !important; } }

@media screen and (max-width: 767px) {
  .pl_55--sp {
    padding-left: 55px !important; } }

@media screen and (max-width: 767px) {
  .p_55--sp {
    padding: 55px !important; } }

@media screen and (max-width: 767px) {
  .pt_50--sp {
    padding-top: 50px !important; } }

@media screen and (max-width: 767px) {
  .pb_50--sp {
    padding-bottom: 50px !important; } }

@media screen and (max-width: 767px) {
  .pr_50--sp {
    padding-right: 50px !important; } }

@media screen and (max-width: 767px) {
  .pl_50--sp {
    padding-left: 50px !important; } }

@media screen and (max-width: 767px) {
  .p_50--sp {
    padding: 50px !important; } }

@media screen and (max-width: 767px) {
  .pt_45--sp {
    padding-top: 45px !important; } }

@media screen and (max-width: 767px) {
  .pb_45--sp {
    padding-bottom: 45px !important; } }

@media screen and (max-width: 767px) {
  .pr_45--sp {
    padding-right: 45px !important; } }

@media screen and (max-width: 767px) {
  .pl_45--sp {
    padding-left: 45px !important; } }

@media screen and (max-width: 767px) {
  .p_45--sp {
    padding: 45px !important; } }

@media screen and (max-width: 767px) {
  .pt_40--sp {
    padding-top: 40px !important; } }

@media screen and (max-width: 767px) {
  .pb_40--sp {
    padding-bottom: 40px !important; } }

@media screen and (max-width: 767px) {
  .pr_40--sp {
    padding-right: 40px !important; } }

@media screen and (max-width: 767px) {
  .pl_40--sp {
    padding-left: 40px !important; } }

@media screen and (max-width: 767px) {
  .p_40--sp {
    padding: 40px !important; } }

@media screen and (max-width: 767px) {
  .pt_35--sp {
    padding-top: 35px !important; } }

@media screen and (max-width: 767px) {
  .pb_35--sp {
    padding-bottom: 35px !important; } }

@media screen and (max-width: 767px) {
  .pr_35--sp {
    padding-right: 35px !important; } }

@media screen and (max-width: 767px) {
  .pl_35--sp {
    padding-left: 35px !important; } }

@media screen and (max-width: 767px) {
  .p_35--sp {
    padding: 35px !important; } }

@media screen and (max-width: 767px) {
  .pt_30--sp {
    padding-top: 30px !important; } }

@media screen and (max-width: 767px) {
  .pb_30--sp {
    padding-bottom: 30px !important; } }

@media screen and (max-width: 767px) {
  .pr_30--sp {
    padding-right: 30px !important; } }

@media screen and (max-width: 767px) {
  .pl_30--sp {
    padding-left: 30px !important; } }

@media screen and (max-width: 767px) {
  .p_30--sp {
    padding: 30px !important; } }

@media screen and (max-width: 767px) {
  .pt_25--sp {
    padding-top: 25px !important; } }

@media screen and (max-width: 767px) {
  .pb_25--sp {
    padding-bottom: 25px !important; } }

@media screen and (max-width: 767px) {
  .pr_25--sp {
    padding-right: 25px !important; } }

@media screen and (max-width: 767px) {
  .pl_25--sp {
    padding-left: 25px !important; } }

@media screen and (max-width: 767px) {
  .p_25--sp {
    padding: 25px !important; } }

@media screen and (max-width: 767px) {
  .pt_20--sp {
    padding-top: 20px !important; } }

@media screen and (max-width: 767px) {
  .pb_20--sp {
    padding-bottom: 20px !important; } }

@media screen and (max-width: 767px) {
  .pr_20--sp {
    padding-right: 20px !important; } }

@media screen and (max-width: 767px) {
  .pl_20--sp {
    padding-left: 20px !important; } }

@media screen and (max-width: 767px) {
  .p_20--sp {
    padding: 20px !important; } }

@media screen and (max-width: 767px) {
  .pt_15--sp {
    padding-top: 15px !important; } }

@media screen and (max-width: 767px) {
  .pb_15--sp {
    padding-bottom: 15px !important; } }

@media screen and (max-width: 767px) {
  .pr_15--sp {
    padding-right: 15px !important; } }

@media screen and (max-width: 767px) {
  .pl_15--sp {
    padding-left: 15px !important; } }

@media screen and (max-width: 767px) {
  .p_15--sp {
    padding: 15px !important; } }

@media screen and (max-width: 767px) {
  .pt_10--sp {
    padding-top: 10px !important; } }

@media screen and (max-width: 767px) {
  .pb_10--sp {
    padding-bottom: 10px !important; } }

@media screen and (max-width: 767px) {
  .pr_10--sp {
    padding-right: 10px !important; } }

@media screen and (max-width: 767px) {
  .pl_10--sp {
    padding-left: 10px !important; } }

@media screen and (max-width: 767px) {
  .p_10--sp {
    padding: 10px !important; } }

@media screen and (max-width: 767px) {
  .pt_5--sp {
    padding-top: 5px !important; } }

@media screen and (max-width: 767px) {
  .pb_5--sp {
    padding-bottom: 5px !important; } }

@media screen and (max-width: 767px) {
  .pr_5--sp {
    padding-right: 5px !important; } }

@media screen and (max-width: 767px) {
  .pl_5--sp {
    padding-left: 5px !important; } }

@media screen and (max-width: 767px) {
  .p_5--sp {
    padding: 5px !important; } }

@media screen and (max-width: 767px) {
  .pt_0--sp {
    padding-top: 0px !important; } }

@media screen and (max-width: 767px) {
  .pb_0--sp {
    padding-bottom: 0px !important; } }

@media screen and (max-width: 767px) {
  .pr_0--sp {
    padding-right: 0px !important; } }

@media screen and (max-width: 767px) {
  .pl_0--sp {
    padding-left: 0px !important; } }

@media screen and (max-width: 767px) {
  .p_0--sp {
    padding: 0px !important; } }

/* border
______________________________________________*/
.bdr_t {
  border-top: 1px #c9caca solid; }

.bdr_b {
  border-bottom: 1px #c9caca solid; }

.bdr_l {
  border-left: 1px #c9caca solid; }

.bdr_r {
  border-right: 1px #c9caca solid; }

@media (min-width: 768px) {
  /*
 PC common
---------------------------------------- */
  /* ========== header ========== */
  .header {
    width: 1000px;
    background: #fff;
    margin: 0 auto;
    padding-top: 10px;
    font-size: 14px;
    font-size: 1.4rem; }
    .header_main {
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
    .header_btn ul {
      font-size: 0; }
      .header_btn ul li a:hover {
        opacity: .6; }
    .header_btn_search {
      display: inline-block; }
      .header_btn_search a {
        font-size: 14px;
        font-size: 1.4rem;
        display: block;
        width: 204px;
        line-height: 34px;
        color: #e0c21b;
        border: 1px #e0c21b solid;
        padding-left: 63px;
        background: url(../img/ico_search.svg) no-repeat 15px center; }
    .header_btn_login {
      display: inline-block;
      margin-left: 20px; }
      .header_btn_login a {
        font-size: 14px;
        font-size: 1.4rem;
        display: block;
        width: 154px;
        line-height: 34px;
        color: #00913a;
        border: 1px #00913a solid;
        padding-left: 63px;
        background: url(../img/ico_login.svg) no-repeat 15px center; }
    .header_nav {
      width: 812px;
      margin: 0 auto; }
      .header_nav ul {
        display: flex;
        justify-content: space-between;
        margin-top: 5px; }
        .header_nav ul li a {
          display: block;
          padding: 20px 0;
          color: #595757; }
          .header_nav ul li a:hover {
            color: #00913a; }
  /* ========== footer ========== */
  .footer {
    position: relative;
    overflow: hidden; }
    .footer:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 100%;
      margin: 0 -10% 0;
      background: #00913a;
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1; }
    .footer_wrap {
      padding-top: 170px;
      width: 1130px;
      margin: 0 auto;
      font-size: 14px;
      font-size: 1.4rem;
      text-align: center; }
    .footer_logo {
      padding-bottom: 40px; }
      .footer_logo img {
        margin: 0 auto;
        text-align: center; }
    .footer_nav {
      padding-bottom: 40px;
      border-bottom: 1px #fff solid; }
      .footer_nav ul {
        padding-bottom: 5px; }
        .footer_nav ul li {
          display: inline-block;
          padding: 0 20px; }
          .footer_nav ul li a {
            color: #fff; }
    .footer_txt {
      color: #fff;
      padding-top: 20px; }
      .footer_txt .copyright {
        padding: 20px 0;
        font-size: 12px;
        font-size: 1.2rem; }
    .footer .footer_nav_sp {
      display: none; }
    .footer .page_top {
      cursor: pointer;
      position: fixed;
      right: 50px;
      bottom: 50px;
      z-index: 9999; }
  .link_bot {
    position: relative;
    z-index: 2; }
    .link_bot ul {
      width: 968px;
      margin: 0 auto -120px;
      display: flex;
      justify-content: space-between; }
      .link_bot ul li {
        width: 286px;
        background: #fff;
        border-radius: 5px; }
        .link_bot ul li a {
          border-radius: 5px;
          display: block;
          text-align: center;
          margin: 0 auto;
          font-size: 17px;
          font-size: 1.7rem;
          color: #fff;
          padding-bottom: 10px; }
          .link_bot ul li a img {
            text-align: center;
            margin: 0 auto;
            padding: 20px 0 10px; }
    .link_bot_search a {
      background: #e0c21b;
      border-bottom: 4px #a38a0d solid; }
    .link_bot_login a {
      background: #00913a;
      border-bottom: 4px #006934 solid; }
    .link_bot_contact a {
      background: #ea5514;
      border-bottom: 4px #ad380e solid; }
  .top {
    background: url(../img/bg_01.png) no-repeat center top;
    background-size: 100% auto;
    background-attachment: fixed;
    padding: 85px 0 95px 0;
    text-align: center; }
    .top .top-title {
      display: inline-block;
      text-align: center;
      width: auto; }
      .top .top-title .en {
        display: block;
        font-size: 44px;
        font-size: 4.4rem;
        color: #00913a;
        font-family: "Raleway", sans-serif;
        font-weight: 100;
        line-height: 1.3;
        padding: 0 20px; }
      .top .top-title .jp {
        display: block;
        font-size: 18px;
        font-size: 1.8rem;
        color: #FFFFFF;
        width: 100%;
        line-height: 23px;
        background-color: #00913a;
        padding: 0; }
  .page-description {
    text-align: center;
    padding: 90px 0 70px 0; }
    .page-description .content {
      width: 736px;
      height: auto;
      margin: 0 auto; }
      .page-description .content .title {
        text-align: left;
        font-size: 28px;
        font-size: 2.8rem;
        color: #333; }
      .page-description .content hr {
        width: 32px;
        border-color: #333;
        border-width: 1px;
        margin: 25px auto; }
      .page-description .content .text {
        text-align: left;
        font-size: 16px;
        font-size: 1.6rem;
        color: #333; }
  .pdf-download-button {
    display: block;
    width: 750px;
    height: 230px;
    margin: 160px auto 0; }
    .pdf-download-button img {
      width: 750px;
      height: 230px; }
  /* ========== tit ========== */
  .min_tit {
    font-size: 28px;
    font-size: 2.8rem;
    text-align: center;
    padding-top: 30px; }
    .min_tit:after {
      margin: 20px auto;
      content: '';
      display: block;
      text-align: center;
      width: 32px;
      height: 1px;
      background: #333; }
  .sec_tit {
    width: 540px;
    text-align: center;
    margin: 0 auto; }
    .sec_tit .en {
      font-size: 70px;
      font-size: 7rem;
      font-weight: 100;
      font-family: "Raleway", sans-serif;
      display: block;
      line-height: 1.4; }
      .sec_tit .en:after {
        margin: 10px auto;
        content: '';
        display: block;
        text-align: center;
        width: 32px;
        height: 1px;
        background: #333; }
    .sec_tit .jp {
      font-size: 24px;
      font-size: 2.4rem;
      display: block;
      padding-bottom: 50px; }
    .sec_tit.add-shape {
      background-image: url(../img/title-shape.png);
      background-repeat: no-repeat; }
  .tit--green {
    text-align: center; }
    .tit--green .en {
      display: block;
      font-size: 56px;
      font-size: 5.6rem;
      line-height: 1.3;
      font-weight: 100;
      color: #00913a;
      font-family: "Raleway", sans-serif; }
    .tit--green .jp {
      display: block;
      font-size: 22px;
      font-size: 2.2rem;
      color: #FFF;
      background-color: #00913A;
      width: 260px;
      margin: 0 auto;
      line-height: 30px; }
      .tit--green .jp::before {
        content: "";
        display: block;
        width: 1000px;
        height: 1px;
        background-color: #00913A;
        position: relative;
        top: 15px;
        left: -370px;
        z-index: -1; }
  .tit--leftborder {
    width: 100%;
    height: auto;
    padding: 10px 30px;
    background-color: #F7F8F8;
    border-left: solid 6px #00913A;
    font-size: 24px;
    font-size: 2.4rem;
    color: #333;
    line-height: 1; }
  /* ========== btn ========== */
  .btn_default {
    width: 288px;
    margin: 0 auto; }
    .btn_default a,
    .btn_default button,
    .btn_default input[type="submit"],
    .btn_default input[type="button"] {
      display: block;
      background: #00913a url(../img/ico_arw.svg) no-repeat 90% center;
      border-radius: 5px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      font-size: 2rem;
      font-family: "Noto Sans Japanese", sans-serif;
      text-align: center;
      border: none;
      border-bottom: 5px #006934 solid;
      width: 100%; }
    .btn_default button:disabled {
      background: #c9caca url(../img/ico_arw.svg) no-repeat 90% center;
      border-bottom: 5px #888 solid;
      cursor: default; }
  .btn_min {
    width: 154px; }
    .btn_min a {
      display: block;
      background: #00913a;
      color: #fff;
      border-radius: 3px;
      text-align: center; }
  /*
 PC home
---------------------------------------- */
  .home .key {
    background: url(../img/bg_01.png) no-repeat center top;
    background-size: 100% auto;
    background-attachment: fixed;
    padding-bottom: 520px; }
  .home .copy {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px; }
    .home .copy_txt {
      padding-top: 40px;
      font-size: 34px;
      font-size: 3.4rem;
      font-family: "Noto Serif", serif;
      line-height: 1.9; }
    .home .copy_img {
      padding-right: 34px; }
  .home .thought_txt {
    text-align: center;
    padding-bottom: 60px; }
  .home .features {
    padding-bottom: 20px;
    margin-top: -400px; }
    .home .features_inner {
      width: 1130px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px; }
    .home .features_cont a {
      display: block;
      width: 350px;
      height: 350px;
      border-radius: 50%;
      background: #fff;
      border: 2px #d6eae2 solid;
      text-align: center;
      position: relative;
      z-index: 1; }
      .home .features_cont a:hover {
        opacity: 1;
        border: 2px #00913a solid; }
    .home .features_cont .tit_en {
      font-family: "Raleway", sans-serif;
      font-weight: 100;
      padding: 70px 20px 0;
      font-size: 44px;
      font-size: 4.4rem;
      display: inline-block;
      position: relative;
      text-align: center;
      margin: 0 auto;
      line-height: 1.3; }
      .home .features_cont .tit_en span {
        position: relative;
        z-index: 2; }
      .home .features_cont .tit_en:before {
        content: '';
        display: block;
        position: absolute;
        text-align: center;
        width: 105%;
        bottom: 0;
        left: -2.5%;
        z-index: 1;
        margin: 0 auto;
        height: 16px;
        background: #f2e003; }
    .home .features_cont .tit {
      font-size: 24px;
      font-size: 2.4rem;
      padding: 15px 0 5px; }
    .home .features_cont .txt {
      width: 246px;
      margin: 0 auto;
      text-align: left;
      line-height: 1.8; }
  .home .curriculum {
    position: relative;
    overflow: hidden; }
    .home .curriculum:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 80%;
      margin: 0 -10%;
      background: -moz-linear-gradient(left, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: -webkit-linear-gradient(left, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: linear-gradient(to right, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3e0c11d', endColorstr='#b300913a', GradientType=1);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1; }
    .home .curriculum_wrap {
      padding: 170px 0;
      width: 1130px;
      margin: 0 auto; }
    .home .curriculum_txt {
      width: 915px;
      margin: 0 auto; }
    .home .curriculum_inner {
      display: flex;
      justify-content: space-between;
      padding: 50px 0 60px; }
    .home .curriculum_cont a {
      display: block;
      width: 264px;
      height: 264px;
      position: relative; }
      .home .curriculum_cont a .txt {
        width: 142px;
        height: 142px;
        background: rgba(0, 105, 52, 0.8);
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        margin: -71px 0 0 -71px;
        padding-top: 20px; }
        .home .curriculum_cont a .txt_en {
          font-family: "Raleway", sans-serif;
          font-weight: 100;
          font-size: 29px;
          font-size: 2.9rem; }
        .home .curriculum_cont a .txt_jp {
          font-size: 18px;
          font-size: 1.8rem; }
    .home .curriculum_cont:first-child a {
      background: url(../img/index_img_03.png) no-repeat center;
      background-size: 100%; }
      .home .curriculum_cont:first-child a:hover {
        background-size: 110%;
        opacity: 1; }
    .home .curriculum_cont:nth-of-type(2) a {
      background: url(../img/index_img_04.png) no-repeat center;
      background-size: 100%; }
      .home .curriculum_cont:nth-of-type(2) a:hover {
        background-size: 110%;
        opacity: 1; }
    .home .curriculum_cont:nth-of-type(3) a {
      background: url(../img/index_img_05.png) no-repeat center;
      background-size: 100%; }
      .home .curriculum_cont:nth-of-type(3) a:hover {
        background-size: 110%;
        opacity: 1; }
    .home .curriculum_cont:nth-of-type(4) a {
      background: url(../img/index_img_06.png) no-repeat center;
      background-size: 100%; }
      .home .curriculum_cont:nth-of-type(4) a:hover {
        background-size: 110%;
        opacity: 1; }
    .home .curriculum .btn_default {
      padding-bottom: 110px; }
  .home .news {
    width: 1000px;
    margin: -120px auto 0; }
    .home .news .sec_tit .jp {
      padding-bottom: 10px; }
    .home .news .btn_min {
      margin: 0 0 20px auto; }
    .home .news_inner {
      height: 380px;
      border: 1px #c9caca solid;
      overflow-y: scroll;
      padding: 10px 25px;
      margin-bottom: 80px; }
      .home .news_inner a {
        display: block; }
        .home .news_inner a:hover {
          opacity: .6; }
      .home .news_inner .category {
        width: 126px;
        padding: 7px 0;
        text-align: center;
        font-size: 14px;
        font-size: 1.4rem;
        color: #fff;
        margin-top: 10px;
        float: left; }
        .home .news_inner .category.update {
          background: #81ccbe; }
        .home .news_inner .category.event {
          background: #f7a081; }
      .home .news_inner li {
        border-bottom: 1px #c9caca solid;
        padding: 20px 0; }
      .home .news_inner .date {
        width: 130px;
        min-height: 38px;
        padding: 0 25px;
        float: left; }
        .home .news_inner .date .new {
          display: block;
          line-height: 1;
          font-weight: bold;
          color: #e33315; }
      .home .news_inner .txt {
        min-height: 48px; }
  /*
 PC features 特長ページ
---------------------------------------- */
  .features-page .container {
    margin: 0 0 70px 0; }
  .features-page .features {
    position: relative;
    overflow: hidden; }
    .features-page .features:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 90%;
      margin: 0 -10%;
      background: #e0c11d;
      background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
      opacity: 0.3; }
    .features-page .features .feature-list {
      width: 1000px;
      height: auto;
      margin: 0 auto; }
      .features-page .features .feature-list .feature-colmun {
        margin: 90px auto 0;
        width: 1000px;
        height: auto; }
        .features-page .features .feature-list .feature-colmun:first-child {
          margin: 0; }
        .features-page .features .feature-list .feature-colmun .content {
          margin: 95px 0 0 0;
          font-size: 0; }
          .features-page .features .feature-list .feature-colmun .content img {
            display: inline-block;
            vertical-align: top;
            width: 450px;
            height: 280px; }
          .features-page .features .feature-list .feature-colmun .content p {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 450px);
            font-size: 16px;
            font-size: 1.6rem; }
            .features-page .features .feature-list .feature-colmun .content p.left-item {
              float: left;
              padding-right: 40px; }
            .features-page .features .feature-list .feature-colmun .content p.right-item {
              float: right;
              padding-left: 40px; }
  /*
 PC curriculum カリキュラム
---------------------------------------- */
  .curriculum-page .container {
    margin: 0 0 70px 0;
    overflow: hidden; }
  .curriculum-page .curriculum {
    position: relative; }
    .curriculum-page .curriculum:before {
      content: '';
      position: absolute;
      top: 1000px;
      left: 0;
      width: 120%;
      height: 75%;
      margin: 0 -10%;
      background: #e0c11d;
      background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
      opacity: 0.3; }
    .curriculum-page .curriculum .curriculum-graph-wrapper {
      display: block;
      width: 740px;
      height: 710px;
      margin: 0 auto; }
    .curriculum-page .curriculum .curriculum-list {
      margin: 210px auto 0;
      width: 1000px;
      height: auto; }
      .curriculum-page .curriculum .curriculum-list .curriculum-colmun {
        margin: 90px auto 0;
        width: 1000px;
        height: auto; }
        .curriculum-page .curriculum .curriculum-list .curriculum-colmun:first-child {
          margin: 0; }
        .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content {
          margin: 95px 0 0 0;
          font-size: 0; }
          .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content img {
            display: inline-block;
            vertical-align: top;
            width: 450px;
            height: 280px; }
          .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content p {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 450px);
            font-size: 16px;
            font-size: 1.6rem; }
            .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content p.left-item {
              float: left;
              padding-right: 40px; }
            .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content p.right-item {
              float: right;
              padding-left: 40px; }
  /*
 PC instructor 講師紹介
---------------------------------------- */
  .instructor-page .container {
    margin: 0 0 70px 0; }
  .instructor-page .instructor {
    padding: 0 0 75px 0;
    position: relative;
    display: block; }
    .instructor-page .instructor .instructor-list {
      width: 830px;
      height: auto;
      margin: 60px auto 0; }
      .instructor-page .instructor .instructor-list .instructor-item {
        display: block;
        width: 830px;
        height: auto;
        padding: 55px 48px 58px 66px;
        box-sizing: border-box;
        font-size: 0;
        margin: 50px 0 0 0;
        background-color: #fff;
        position: relative; }
        .instructor-page .instructor .instructor-list .instructor-item::after {
          content: '\00a0';
          display: block;
          position: absolute;
          top: -3px;
          left: -3px;
          z-index: -1;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          background: #e0c11d;
          background: -moz-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
          background: -webkit-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
          background: linear-gradient(135deg, #e0c11d 0%, #00913a 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=1); }
        .instructor-page .instructor .instructor-list .instructor-item:first-child {
          margin: 0; }
        .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper {
          display: inline-block;
          vertical-align: top;
          width: 240px;
          height: auto;
          text-align: center; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img {
            display: inline-block;
            vertical-align: top; }
            .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img.large {
              width: 240px; }
            .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img.small {
              width: 150px; }
        .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 240px);
          padding: 30px 0 0 40px;
          text-align: left; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-name {
            font-size: 24px;
            font-size: 2.4rem;
            color: #333;
            width: 100%;
            border: solid #333;
            border-width: 0 0 1px 0; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-affiliation {
            margin: 15px 0 0 0;
            font-size: 16px;
            font-size: 1.6rem;
            color: #00913A; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-field {
            margin: 25px 0 0 0;
            font-size: 16px;
            font-size: 1.6rem;
            color: #333; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-description {
            margin: 20px 0 0 0;
            font-size: 14px;
            font-size: 1.4rem;
            color: #333; }
  .instructor-page .pdf-download-button {
    margin: 80px auto 0; }
  /*
 PC access アクセス
---------------------------------------- */
  .access-page .container {
    margin: 0 0 70px 0; }
  .access-page .map-image-wrapper {
    width: 495px;
    height: 495px;
    margin: 95px auto; }
  .access-page section.location-info .access-info-list {
    display: block;
    width: 1000px;
    height: auto;
    margin: 100px auto 0; }
    .access-page section.location-info .access-info-list .access-info-item {
      width: 1000px;
      height: auto;
      font-size: 0;
      margin: 25px 0 0 0; }
      .access-page section.location-info .access-info-list .access-info-item:first-child {
        margin: 0; }
      .access-page section.location-info .access-info-list .access-info-item img {
        display: inline-block;
        vertical-align: top;
        width: 450px;
        height: 280px; }
      .access-page section.location-info .access-info-list .access-info-item .text-wrapper {
        display: inline-block;
        vertical-align: top;
        width: 550px;
        height: auto;
        padding: 0 0 0 40px;
        box-sizing: border-box;
        line-height: 1; }
        .access-page section.location-info .access-info-list .access-info-item .text-wrapper .title {
          font-size: 24px;
          font-size: 2.4rem;
          color: #00913A; }
        .access-page section.location-info .access-info-list .access-info-item .text-wrapper .content {
          font-size: 16px;
          font-size: 1.6rem;
          color: #333;
          margin: 25px 0 0 0;
          line-height: 31px; }
  .access-page section.location-info .googlemap-wrapper {
    width: 1000px;
    height: auto;
    margin: 90px auto 0; }
    .access-page section.location-info .googlemap-wrapper .see-in-google-map {
      font-size: 24px;
      font-size: 2.4rem;
      color: #00913A; }
    .access-page section.location-info .googlemap-wrapper .googlemap-content {
      margin: 25px 0 0 0;
      width: 100%;
      height: 540px;
      background-color: #FDF6ED;
      position: relative; }
      .access-page section.location-info .googlemap-wrapper .googlemap-content iframe,
      .access-page section.location-info .googlemap-wrapper .googlemap-content object,
      .access-page section.location-info .googlemap-wrapper .googlemap-content embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  /*
 PC operating-company 運営会社
---------------------------------------- */
  .operating-company-page .container {
    margin: 0 0 150px 0; }
  .operating-company-page .company-info {
    margin: 95px auto 0;
    padding: 80px 0 0 0;
    width: 1000px;
    border-top: solid 2px #DBDCDC;
    position: relative; }
    .operating-company-page .company-info::before {
      content: ' ';
      position: absolute;
      top: -2px;
      left: 405px;
      width: 190px;
      height: 2px;
      background-color: #00913A; }
    .operating-company-page .company-info .company-info-table {
      display: block;
      width: 810px;
      height: auto;
      margin: 0 auto; }
      .operating-company-page .company-info .company-info-table tbody {
        width: 100%;
        height: auto; }
        .operating-company-page .company-info .company-info-table tbody tr {
          display: block;
          width: 810px;
          height: auto;
          padding: 20px 0;
          box-sizing: border-box;
          border-bottom: solid 1px #DBDCDC; }
          .operating-company-page .company-info .company-info-table tbody tr th {
            vertical-align: top;
            width: 180px;
            height: 100%;
            text-align: center;
            font-size: 16px;
            font-size: 1.6rem;
            font-weight: bold;
            -moz-text-align-last: justify;
            text-align-last: justify;
            text-justify: inter-ideograph;
            border: none;
            padding: 0 25px; }
          .operating-company-page .company-info .company-info-table tbody tr td {
            vertical-align: top;
            width: calc(100% - 160px);
            height: 100%;
            font-size: 16px;
            font-size: 1.6rem;
            padding: 0 0 0 20px; }
  /*
 PC inquiry お問い合わせ
---------------------------------------- */
  .required {
    display: inline-block;
    width: 60px;
    height: 20px;
    background-color: #E50012;
    text-align: center;
    line-height: 20px;
    border-radius: 3px;
    margin: 0 10px; }
    .required::before {
      content: "必須";
      font-size: 16px;
      font-size: 1.6rem;
      color: #FFF; }
  .inquiry-page .container {
    margin: 0 0 50px 0; }
  .inquiry-page .inquiry-form-box {
    margin: 90px auto 0;
    width: 1130px;
    height: auto; }
    .inquiry-page .inquiry-form-box .inquiry-attention {
      width: 100%;
      height: auto;
      border: solid 1px #B5B5B6;
      padding: 30px 115px;
      box-sizing: border-box; }
      .inquiry-page .inquiry-form-box .inquiry-attention p {
        vertical-align: middle;
        font-size: 16px;
        font-size: 1.6rem;
        color: #333; }
    .inquiry-page .inquiry-form-box .inquiry-form {
      margin: 50px auto 0;
      width: 1130px;
      height: auto; }
      .inquiry-page .inquiry-form-box .inquiry-form table {
        width: 1130px; }
        .inquiry-page .inquiry-form-box .inquiry-form table th {
          width: 280px;
          height: auto;
          background-color: #F7F8F8;
          padding: 30px 25px;
          box-sizing: border-box;
          border: solid 1px #B5B5B6; }
        .inquiry-page .inquiry-form-box .inquiry-form table td {
          padding: 25px 50px;
          box-sizing: border-box;
          border: solid 1px #B5B5B6; }
          .inquiry-page .inquiry-form-box .inquiry-form table td input,
          .inquiry-page .inquiry-form-box .inquiry-form table td textarea {
            width: 100%;
            height: 34px;
            border-radius: 5px;
            border: solid 1px #B5B5B6; }
          .inquiry-page .inquiry-form-box .inquiry-form table td textarea {
            height: 180px; }
      .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper {
        margin: 40px auto 0;
        width: 975px;
        height: auto; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information {
          margin: 15px 0 0 0;
          width: 100%;
          height: 290px;
          border: solid 1px #9E9E9F;
          padding: 10px 20px;
          box-sizing: border-box;
          overflow-y: scroll; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check {
          margin: 65px auto 0;
          width: 100%;
          height: auto;
          text-align: center;
          font-size: 0; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check input[type="checkbox"] {
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 7px;
            left: 0; }
            .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check input[type="checkbox"]:hover {
              cursor: pointer; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check label {
            display: inline-block;
            vertical-align: top;
            text-align: left;
            font-size: 16px;
            font-size: 1.6rem;
            color: #333; }
            .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check label:hover {
              cursor: pointer; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .inquiry-button {
          margin: 40px auto 0; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .inquiry-button button:hover {
            cursor: pointer; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .about-holiday {
          margin: 70px 0 0 0;
          font-size: 16px;
          font-size: 1.6rem; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .contact-info {
          margin: 50px 0 0 0;
          font-size: 16px;
          font-size: 1.6rem; }
  /*
 PC news 新着情報
---------------------------------------- */
  .news-page .container {
    margin: 0 0 70px 0; }
  .news-page .news-section {
    margin: 100px auto 0;
    display: block;
    width: 1000px;
    height: auto; }
    .news-page .news-section .type-list {
      width: 100%;
      height: auto;
      border-bottom: solid 1px #9FA0A0;
      font-size: 0; }
      .news-page .news-section .type-list li {
        display: inline-block;
        vertical-align: bottom;
        font-size: 18px;
        font-size: 1.8rem;
        color: #333;
        margin: 0 40px;
        box-sizing: border-box; }
        .news-page .news-section .type-list li a {
          position: relative;
          width: auto;
          height: 100%;
          padding: 0 0 5px 0; }
          .news-page .news-section .type-list li a::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 5px;
            background-color: #009E41;
            transition: all .3s; }
          .news-page .news-section .type-list li a:hover {
            opacity: 1; }
            .news-page .news-section .type-list li a:hover::after {
              content: ' ';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 5px;
              background-color: #009E41; }
        .news-page .news-section .type-list li.selected a::after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: #009E41; }
    .news-page .news-section .news-list .news-item {
      border-bottom: solid 1px #c9caca;
      font-size: 0;
      padding: 20px 45px; }
      .news-page .news-section .news-list .news-item .type {
        display: inline-block;
        vertical-align: text-top;
        width: 125px;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        font-size: 1.4rem;
        color: #FFF;
        text-align: center;
        background-color: #81CCBE;
        margin-top: 10px; }
        .news-page .news-section .news-list .news-item .type.update {
          background-color: #81CCBE; }
        .news-page .news-section .news-list .news-item .type.event {
          background-color: #F7A081; }
      .news-page .news-section .news-list .news-item .date {
        display: inline-block;
        vertical-align: text-top;
        font-size: 16px;
        font-size: 1.6rem;
        width: 100px;
        padding: 0 0 0 30px;
        position: relative; }
        .news-page .news-section .news-list .news-item .date.is-new::after {
          display: block;
          content: "NEW!";
          position: relative;
          color: #E33315;
          font-weight: bold;
          line-height: 1; }
      .news-page .news-section .news-list .news-item .title {
        display: inline-block;
        vertical-align: text-top;
        font-size: 16px;
        font-size: 1.6rem;
        width: 685px;
        padding: 0 0 0 25px; }
    .news-page .news-section .pagination {
      margin: 120px 0 0 0;
      width: 100%;
      height: auto;
      text-align: center; }
      .news-page .news-section .pagination .pages {
        display: inline-block;
        text-align: center;
        font-size: 0; }
        .news-page .news-section .pagination .pages a {
          display: inline-block;
          vertical-align: top;
          width: 57px;
          height: 57px;
          background-color: #FFF;
          border-bottom: solid 3px #ACA59B;
          font-size: 16.8px;
          font-size: 1.68rem;
          color: #CFC3AC;
          text-align: center;
          line-height: 57px;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          margin: 0 0 0 4px; }
          .news-page .news-section .pagination .pages a:first-child {
            margin: 0; }
          .news-page .news-section .pagination .pages a.current {
            background-color: #65655E;
            border-color: #464546;
            color: #FFF; }
            .news-page .news-section .pagination .pages a.current a {
              cursor: default;
              color: #FFF; }
          .news-page .news-section .pagination .pages a.prev {
            color: #9D9D9E;
            background-color: #F6F6F6; }
            .news-page .news-section .pagination .pages a.prev a {
              color: #9D9D9E; }
          .news-page .news-section .pagination .pages a.next {
            color: #9D9D9E;
            background-color: #F6F6F6; }
            .news-page .news-section .pagination .pages a.next a {
              color: #9D9D9E; }
  .news-detail-page .container {
    margin: 0 0 70px 0; }
  .news-detail-page .news-detail-section {
    margin: 95px auto 0;
    width: 1000px;
    height: auto; }
    .news-detail-page .news-detail-section .content {
      margin: 55px auto 0;
      width: 910px;
      height: auto; }
    .news-detail-page .news-detail-section .back-to-list-button {
      margin: 120px auto 0;
      display: block;
      width: 175px;
      height: 175px;
      border-radius: 50%;
      background-color: #009E41;
      font-size: 24px;
      font-size: 2.4rem;
      color: #FFF;
      text-align: center;
      line-height: 175px; }
  /*
 PC personal-information 個人情報
---------------------------------------- */
  .personal-information-page .about-personal-information {
    margin: 95px auto 0;
    width: 1000px; }
    .personal-information-page .about-personal-information .content-wrapper {
      width: 100%;
      height: auto;
      padding: 55px 50px;
      box-sizing: border-box; }
      .personal-information-page .about-personal-information .content-wrapper .sub-title {
        margin: 40px 0 0 0;
        font-size: 16px;
        font-size: 1.6rem;
        color: #00913A; }
        .personal-information-page .about-personal-information .content-wrapper .sub-title:first-child {
          margin: 0; }
      .personal-information-page .about-personal-information .content-wrapper .content {
        font-size: 16px;
        font-size: 1.6rem;
        color: #333; }
    .personal-information-page .about-personal-information .privacy-mark-wrapper {
      margin: 60px 0 115px 0;
      width: 100%;
      padding-left: 50px;
      height: auto; }
      .personal-information-page .about-personal-information .privacy-mark-wrapper .privacy-mark-link {
        display: inline-block;
        vertical-align: top;
        width: 100px;
        height: auto; }
        .personal-information-page .about-personal-information .privacy-mark-wrapper .privacy-mark-link .privacy-mark-img {
          width: 100%;
          height: auto; }
      .personal-information-page .about-personal-information .privacy-mark-wrapper .description {
        display: inline-block;
        vertical-align: top;
        width: 657px;
        height: auto;
        text-align: left;
        margin: 0 0 0 10px; } }

@media (max-width: 767px) {
  /*
 SP common
---------------------------------------- */
  /* ========== header ========== */
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 43px;
    background: #fff;
    margin: 0 auto;
    padding: 10px 0;
    z-index: 999; }
    .header_main {
      padding: 0 4%;
      position: relative;
      top: -5px;
      left: 0; }
    .header_logo {
      width: 160px; }
    .header_btn {
      display: none; }
    .header_nav {
      display: block;
      position: fixed;
      top: 43px;
      left: 100vw;
      width: 100vw;
      height: auto;
      background-color: #2E3641;
      opacity: 0;
      transition-duration: 0.3s; }
      .header_nav ul {
        width: 100%;
        height: auto; }
        .header_nav ul li {
          display: block;
          width: 100%;
          height: 45px;
          background-color: #2E3641;
          border: solid #DBDCDC;
          border-width: 1px 0 1px 0;
          margin: -1px 0 0 0; }
          .header_nav ul li a {
            display: block;
            width: 100%;
            height: 45px;
            padding: 0 0 0 15px;
            box-sizing: border-box;
            line-height: 45px;
            color: #FFF; }
      .header_nav.isOpen {
        display: block;
        position: fixed;
        top: 43px;
        left: 0;
        opacity: 1;
        transition-duration: 0.3s; }
    .header_mobile-menu-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 43px;
      height: 43px; }
      .header_mobile-menu-button .mobile-menu-button {
        position: relative;
        width: 43px;
        height: 43px;
        background-color: #DBDCDC;
        border: none;
        padding: 0; }
        .header_mobile-menu-button .mobile-menu-button .mobile-menu-button-border {
          position: absolute;
          top: 15px;
          left: 11.5px;
          width: 20px;
          height: 2px;
          background-color: #000;
          transition-duration: 0.3s; }
          .header_mobile-menu-button .mobile-menu-button .mobile-menu-button-border.item1 {
            top: 15px; }
          .header_mobile-menu-button .mobile-menu-button .mobile-menu-button-border.item2 {
            top: 20px; }
          .header_mobile-menu-button .mobile-menu-button .mobile-menu-button-border.item3 {
            top: 25px; }
        .header_mobile-menu-button .mobile-menu-button.isOpen {
          background-color: #333; }
          .header_mobile-menu-button .mobile-menu-button.isOpen .mobile-menu-button-border {
            position: absolute;
            top: 15px;
            left: 11.5px;
            width: 20px;
            height: 2px;
            background-color: #000;
            transition-duration: 0.3s; }
            .header_mobile-menu-button .mobile-menu-button.isOpen .mobile-menu-button-border.item1 {
              top: 20px;
              background-color: #FFF;
              transition-duration: 0.3s;
              transform: rotate(-45deg); }
            .header_mobile-menu-button .mobile-menu-button.isOpen .mobile-menu-button-border.item2 {
              top: 20px;
              background-color: #FFF;
              transition-duration: 0.3s;
              transform: rotate(45deg); }
            .header_mobile-menu-button .mobile-menu-button.isOpen .mobile-menu-button-border.item3 {
              top: 20px;
              background-color: #FFF;
              transition-duration: 0.3s;
              transform: rotate(45deg); }
      .header_mobile-menu-button .mobile-menu-button {
        position: relative;
        width: 43px;
        height: 43px;
        background-color: #DBDCDC;
        border: none;
        padding: 0; }
        .header_mobile-menu-button .mobile-menu-button .border1 {
          position: absolute;
          top: 15px;
          left: 11.5px;
          width: 20px;
          height: 2px;
          background-color: #000; }
        .header_mobile-menu-button .mobile-menu-button .border2 {
          position: absolute;
          top: 20px;
          left: 11.5px;
          width: 20px;
          height: 2px;
          background-color: #000; }
        .header_mobile-menu-button .mobile-menu-button .border3 {
          position: absolute;
          top: 25px;
          left: 11.5px;
          width: 20px;
          height: 2px;
          background-color: #000; }
  /* ========== main ========== */
  main {
    margin: 43px 0 0 0; }
  /* ========== footer ========== */
  .footer {
    position: relative;
    overflow: hidden; }
    .footer:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 100%;
      margin: 0 -10% 0;
      background: #00913a;
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1; }
    .footer_wrap {
      padding-top: 60px;
      width: 100%;
      margin: 0 auto;
      text-align: center; }
    .footer_logo {
      padding-bottom: 40px; }
      .footer_logo img {
        margin: 0 auto;
        text-align: center; }
    .footer_nav {
      display: none; }
    .footer_txt {
      color: #fff; }
      .footer_txt .copyright {
        padding: 40px 0 20px;
        font-size: 10px;
        font-size: 1rem; }
    .footer_nav_sp ul {
      margin-top: 30px; }
      .footer_nav_sp ul li {
        margin: 0 4% 10px; }
        .footer_nav_sp ul li a {
          display: block;
          border: 1px #fff solid;
          background: #37a34a;
          text-align: center;
          color: #fff;
          line-height: 35px; }
    .footer_nav_sp .contact_txt {
      text-align: center;
      padding: 10px 0; }
    .footer_nav_sp .contact_btn {
      margin: 0 4%; }
      .footer_nav_sp .contact_btn a {
        display: block;
        border: 1px #fff solid;
        background: #37a34a;
        text-align: center;
        color: #fff;
        padding: 20px 0 10px; }
        .footer_nav_sp .contact_btn a img {
          width: 10%;
          margin: 0 auto 5px; }
    .footer .page_top {
      width: 43px;
      cursor: pointer;
      position: fixed;
      right: 5px;
      bottom: 5px;
      z-index: 9999; }
  .link_bot {
    position: relative;
    z-index: 2; }
    .link_bot ul {
      width: 100%;
      margin: 0 auto; }
      .link_bot ul li {
        width: 80%;
        margin: 0 auto 15px; }
        .link_bot ul li a {
          border-radius: 5px;
          display: block;
          text-align: center;
          margin: 0 auto;
          font-size: 15px;
          font-size: 1.5rem;
          color: #fff;
          padding-bottom: 8px; }
          .link_bot ul li a img {
            width: 18%;
            text-align: center;
            margin: 0 auto;
            padding: 15px 0 5px; }
    .link_bot_search a {
      background: #e0c21b;
      border-bottom: 4px #a38a0d solid; }
    .link_bot_login a {
      background: #00913a;
      border-bottom: 4px #006934 solid; }
    .link_bot_contact a {
      background: #ea5514;
      border-bottom: 4px #ad380e solid; }
  .top {
    background: url(../img/bg_01.png) no-repeat center top;
    background-size: 100% auto;
    background-attachment: fixed;
    padding: 49px 0 43.5px 0;
    text-align: center; }
    .top .top-title {
      display: inline-block;
      text-align: center;
      width: 80%;
      height: auto; }
      .top .top-title .en {
        display: block;
        font-size: 20px;
        font-size: 2rem;
        color: #00913A;
        padding: 0;
        font-family: "Raleway", sans-serif;
        line-height: 1.4; }
      .top .top-title .jp {
        display: block;
        font-size: 13px;
        font-size: 1.3rem;
        color: #FFFFFF;
        line-height: 20px;
        background-color: #00913A; }
  .page-description {
    text-align: center;
    padding: 62.5px 5% 70px 5%; }
    .page-description .content {
      width: 100%;
      height: auto;
      margin: 0 auto; }
      .page-description .content .title {
        text-align: left;
        font-size: 17px;
        font-size: 1.7rem;
        color: #333; }
      .page-description .content hr {
        width: 32px;
        border-color: #333;
        border-width: 1px;
        margin: 25px auto; }
      .page-description .content .text {
        text-align: left;
        color: #333; }
  .pdf-download-button {
    display: block;
    width: 80%;
    height: auto;
    margin: 120px auto 15px; }
    .pdf-download-button img {
      width: 100%;
      height: auto; }
  /* ========== tit ========== */
  .min_tit {
    font-size: 17px;
    font-size: 1.7rem;
    text-align: center;
    padding-top: 15px; }
    .min_tit:after {
      margin: 10px auto 20px;
      content: '';
      display: block;
      text-align: center;
      width: 24px;
      height: 1px;
      background: #333; }
  .sec_tit {
    text-align: center; }
    .sec_tit .en {
      font-size: 54px;
      font-size: 5.4rem;
      font-weight: 100;
      font-family: "Raleway", sans-serif;
      display: block;
      line-height: 1.2; }
      .sec_tit .en:after {
        margin: 10px auto;
        content: '';
        display: block;
        text-align: center;
        width: 24px;
        height: 1px;
        background: #333; }
    .sec_tit .jp {
      font-size: 18px;
      font-size: 1.8rem;
      display: block;
      padding-bottom: 30px; }
    .sec_tit.add-shape {
      background-image: url(../img/title-shape.png);
      background-repeat: no-repeat;
      background-size: 150px 165px;
      background-position: -10% 65%; }
  .tit--green {
    text-align: center; }
    .tit--green .en {
      display: block;
      font-size: 42px;
      font-size: 4.2rem;
      color: #00913A;
      font-family: "Raleway", sans-serif;
      line-height: 1; }
    .tit--green .jp {
      margin: 5px auto 0;
      display: block;
      font-size: 16px;
      font-size: 1.6rem;
      color: #FFF;
      background-color: #00913A;
      width: 190px;
      line-height: 20px;
      padding: 0 0 2px; }
    .tit--green:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #00913A;
      position: relative;
      bottom: 11px;
      left: 0;
      z-index: -1; }
  .tit--leftborder {
    width: 100%;
    height: auto;
    padding: 10px 5%;
    background-color: #F7F8F8;
    border-left: solid 6px #00913A;
    font-size: 16px;
    font-size: 1.6rem;
    color: #333;
    line-height: 1; }
  /* ========== btn ========== */
  .btn_default {
    width: 70%;
    margin: 0 auto; }
    .btn_default a,
    .btn_default button,
    .btn_default input[type="submit"],
    .btn_default input[type="button"] {
      display: block;
      background: #00913a url(../img/ico_arw.svg) no-repeat 90% center;
      border-radius: 5px;
      line-height: 37px;
      color: #fff;
      font-size: 15px;
      font-size: 1.5rem;
      font-family: "Noto Sans Japanese", sans-serif;
      text-align: center;
      border: none;
      border-bottom: 3px #006934 solid;
      width: 100%; }
    .btn_default button:disabled {
      background: #c9caca url(../img/ico_arw.svg) no-repeat 90% center;
      border-bottom: 3px #888 solid;
      cursor: default; }
  .btn_min {
    width: 40%; }
    .btn_min a {
      display: block;
      background: #00913a;
      color: #fff;
      padding: 2px 0;
      border-radius: 3px;
      text-align: center; }
  /*
 SP home
---------------------------------------- */
  .home .key {
    background: url(../img/bg_01_sp.png) no-repeat center top;
    background-size: cover;
    padding-bottom: 280px; }
  .home .copy {
    width: 92%;
    margin: 0 auto;
    padding-top: 15px; }
    .home .copy_ribbon {
      width: 80%;
      padding-left: 4%; }
    .home .copy_txt {
      padding-top: 10px;
      font-size: 17px;
      font-size: 1.7rem;
      font-family: "Noto Serif", serif;
      line-height: 1.9; }
    .home .copy_img {
      width: 55%;
      margin: 10px auto 0; }
  .home .thought {
    width: 86%;
    margin: 0 auto; }
    .home .thought_txt {
      text-align: center;
      padding-bottom: 60px; }
  .home .features {
    padding-bottom: 30px;
    margin-top: -300px; }
    .home .features_inner {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px; }
    .home .features_cont a {
      display: block;
      width: 264px;
      height: 264px;
      border-radius: 50%;
      background: #fff;
      border: 2px #d6eae2 solid;
      text-align: center;
      position: relative;
      z-index: 1;
      margin: 0 auto 20px; }
    .home .features_cont .tit_en {
      font-family: "Raleway", sans-serif;
      font-weight: 100;
      padding: 50px 10px 0;
      font-size: 33px;
      font-size: 3.3rem;
      display: inline-block;
      position: relative;
      text-align: center;
      margin: 0 auto;
      line-height: 1.3; }
      .home .features_cont .tit_en span {
        position: relative;
        z-index: 2; }
      .home .features_cont .tit_en:before {
        content: '';
        display: block;
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 1;
        margin: 0 auto;
        height: 12px;
        background: #f2e003; }
    .home .features_cont .tit {
      font-size: 18px;
      font-size: 1.8rem;
      padding: 7px 0 5px; }
    .home .features_cont .txt {
      width: 180px;
      font-size: 12px;
      font-size: 1.2rem;
      margin: 0 auto;
      text-align: left;
      line-height: 1.8; }
  .home .curriculum {
    position: relative;
    overflow: hidden; }
    .home .curriculum:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 140%;
      height: 90%;
      margin: 0 -20%;
      background: -moz-linear-gradient(top, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: -webkit-linear-gradient(top, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: linear-gradient(to bottom, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3e0c11d', endColorstr='#b300913a', GradientType=1);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1; }
    .home .curriculum_wrap {
      padding: 50px 0;
      width: 100%;
      margin: 0 auto; }
    .home .curriculum_txt {
      width: 92%;
      margin: 0 auto; }
    .home .curriculum_inner {
      padding: 25px 0 20px; }
    .home .curriculum_cont {
      margin: 0 auto 20px; }
      .home .curriculum_cont a {
        margin: 0 auto;
        display: block;
        width: 200px;
        height: 200px;
        position: relative; }
        .home .curriculum_cont a .txt {
          width: 106px;
          height: 106px;
          background: rgba(0, 105, 52, 0.8);
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          text-align: center;
          margin: -53px 0 0 -53px;
          padding-top: 15px; }
          .home .curriculum_cont a .txt_en {
            font-family: "Raleway", sans-serif;
            font-weight: 100;
            font-size: 22px;
            font-size: 2.2rem; }
          .home .curriculum_cont a .txt_jp {
            font-size: 13px;
            font-size: 1.3rem; }
      .home .curriculum_cont:first-child a {
        background: url(../img/index_img_03.png) no-repeat center;
        background-size: 100%; }
        .home .curriculum_cont:first-child a:hover {
          background-size: 110%;
          opacity: 1; }
      .home .curriculum_cont:nth-of-type(2) a {
        background: url(../img/index_img_04.png) no-repeat center;
        background-size: 100%; }
        .home .curriculum_cont:nth-of-type(2) a:hover {
          background-size: 110%;
          opacity: 1; }
      .home .curriculum_cont:nth-of-type(3) a {
        background: url(../img/index_img_05.png) no-repeat center;
        background-size: 100%; }
        .home .curriculum_cont:nth-of-type(3) a:hover {
          background-size: 110%;
          opacity: 1; }
      .home .curriculum_cont:nth-of-type(4) a {
        background: url(../img/index_img_06.png) no-repeat center;
        background-size: 100%; }
        .home .curriculum_cont:nth-of-type(4) a:hover {
          background-size: 110%;
          opacity: 1; }
    .home .curriculum .btn_default {
      padding-bottom: 130px; }
  .home .news {
    width: 92%;
    margin: -100px auto 0; }
    .home .news .sec_tit .jp {
      padding-bottom: 10px; }
    .home .news .btn_min {
      margin: 0 0 20px auto; }
    .home .news_inner {
      height: 380px;
      border: 1px #c9caca solid;
      overflow-y: scroll;
      padding: 5px 6%;
      margin-bottom: 40px; }
      .home .news_inner a {
        display: block; }
        .home .news_inner a:hover {
          opacity: .6; }
      .home .news_inner .category {
        width: 115px;
        padding: 5px 0;
        text-align: center;
        font-size: 13px;
        font-size: 1.3rem;
        color: #fff;
        margin-top: 6px;
        display: inline-block;
        vertical-align: top; }
        .home .news_inner .category.update {
          background: #81ccbe; }
        .home .news_inner .category.event {
          background: #f7a081; }
      .home .news_inner li {
        border-bottom: 1px #c9caca solid;
        padding: 15px 0; }
      .home .news_inner .date {
        width: 130px;
        min-height: 38px;
        padding: 0 5%;
        display: inline-block;
        vertical-align: top; }
        .home .news_inner .date .new {
          display: block;
          line-height: 1;
          font-weight: bold;
          color: #e33315; }
      .home .news_inner .txt {
        padding-top: 8px; }
  .features-page .container {
    margin: 0; }
  .features-page .features {
    position: relative;
    overflow: hidden; }
    .features-page .features:before {
      content: '';
      position: absolute;
      top: 0;
      left: -15%;
      width: 180%;
      height: 90%;
      margin: 0 -10%;
      background: #e0c11d;
      background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
      opacity: 0.3; }
    .features-page .features .feature-list {
      width: 100%;
      height: auto;
      padding: 0 5%; }
      .features-page .features .feature-list .feature-colmun {
        margin: 65px 0 0 0;
        width: 100%;
        height: auto; }
        .features-page .features .feature-list .feature-colmun:first-child {
          margin: 0; }
        .features-page .features .feature-list .feature-colmun .content {
          margin: 40px 0 0 0;
          font-size: 0; }
          .features-page .features .feature-list .feature-colmun .content img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: auto; }
          .features-page .features .feature-list .feature-colmun .content p {
            margin: 20px 0 0 0;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            font-size: 14px;
            font-size: 1.4rem; }
  /*
 SP curriculum カリキュラム
---------------------------------------- */
  .curriculum-page .container {
    margin: 0;
    overflow: hidden; }
  .curriculum-page .curriculum {
    position: relative; }
    .curriculum-page .curriculum:before {
      content: '';
      position: absolute;
      top: 450px;
      left: -15%;
      width: 180%;
      height: 90%;
      margin: 0 -20%;
      background: #e0c11d;
      background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
      background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
      opacity: 0.3; }
    .curriculum-page .curriculum .curriculum-graph-wrapper {
      display: block;
      width: 80%;
      height: auto;
      margin: 0 auto; }
    .curriculum-page .curriculum .curriculum-list {
      margin: 20px 0 0 0;
      width: 100%;
      height: auto;
      padding: 0 5%; }
      .curriculum-page .curriculum .curriculum-list .curriculum-colmun {
        margin: 65px 0 0 0;
        width: 100%;
        height: auto; }
        .curriculum-page .curriculum .curriculum-list .curriculum-colmun:first-child {
          margin: 0; }
        .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content {
          margin: 40px 0 0 0;
          font-size: 0; }
          .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: auto; }
          .curriculum-page .curriculum .curriculum-list .curriculum-colmun .content p {
            margin: 20px 0 0 0;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            font-size: 14px;
            font-size: 1.4rem; }
  /*
 SP instructor 講師紹介
---------------------------------------- */
  .instructor-page .container {
    margin: 0; }
  .instructor-page .instructor {
    position: relative;
    display: block; }
    .instructor-page .instructor .instructor-list {
      width: 100%;
      height: auto;
      margin: 60px auto 0;
      padding: 0 5%; }
      .instructor-page .instructor .instructor-list .instructor-item {
        display: block;
        width: 100%;
        height: auto;
        padding: 20px 8% 30px;
        box-sizing: border-box;
        font-size: 0;
        background-color: #fff;
        position: relative;
        margin: 40px 0 0 0; }
        .instructor-page .instructor .instructor-list .instructor-item:first-child {
          margin: 0; }
        .instructor-page .instructor .instructor-list .instructor-item::after {
          content: '\00a0';
          display: block;
          position: absolute;
          top: -3px;
          left: -3px;
          z-index: -1;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          background: #e0c11d;
          background: -moz-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
          background: -webkit-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
          background: linear-gradient(135deg, #e0c11d 0%, #00913a 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=1); }
        .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: auto;
          text-align: center; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img {
            display: inline-block;
            vertical-align: top; }
            .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img .large {
              width: 240px;
              height: 340px; }
            .instructor-page .instructor .instructor-list .instructor-item .instructor-image-wrapper img .small {
              width: 150px;
              height: 340px; }
        .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper {
          margin: 20px 0 0 0;
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: auto;
          text-align: left; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-name {
            font-size: 18px;
            font-size: 1.8rem;
            color: #333;
            width: 100%;
            border: solid #333;
            border-width: 0 0 1px 0; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-affiliation {
            margin: 15px 0 0 0;
            font-size: 15px;
            font-size: 1.5rem;
            color: #00913A; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-field {
            margin: 25px 0 0 0;
            font-size: 15px;
            font-size: 1.5rem;
            color: #333; }
          .instructor-page .instructor .instructor-list .instructor-item .instructor-text-wrapper .instructor-description {
            margin: 20px 0 0 0;
            font-size: 14px;
            font-size: 1.4rem;
            color: #333; }
  /*
 SP access アクセス
---------------------------------------- */
  .access-page .container {
    margin: 0; }
  .access-page .map-image-wrapper {
    width: 92%;
    margin: 50px auto 0; }
  .access-page section.location-info {
    width: 92%;
    margin: 40px auto 0; }
    .access-page section.location-info .access-info-list {
      display: block;
      width: 100%;
      height: auto;
      margin: 50px auto 0; }
      .access-page section.location-info .access-info-list .access-info-item {
        width: 100%;
        height: auto;
        font-size: 0;
        margin: 40px 0 0 0; }
        .access-page section.location-info .access-info-list .access-info-item:first-child {
          margin: 0; }
        .access-page section.location-info .access-info-list .access-info-item img {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: auto; }
        .access-page section.location-info .access-info-list .access-info-item .text-wrapper {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: auto;
          box-sizing: border-box; }
          .access-page section.location-info .access-info-list .access-info-item .text-wrapper .title {
            font-size: 18px;
            font-size: 1.8rem;
            color: #00913A;
            margin: 20px 0 0 0; }
          .access-page section.location-info .access-info-list .access-info-item .text-wrapper .content {
            font-size: 14px;
            font-size: 1.4rem;
            color: #333;
            margin: 10px 0 0 0; }
    .access-page section.location-info .googlemap-wrapper {
      width: 100%;
      height: auto;
      margin: 90px auto 0; }
      .access-page section.location-info .googlemap-wrapper .see-in-google-map {
        font-size: 18px;
        font-size: 1.8rem;
        color: #00913A; }
      .access-page section.location-info .googlemap-wrapper .googlemap-content {
        margin: 10px 0 0 0;
        width: 100%;
        height: 200px;
        background-color: #FDF6ED;
        position: relative; }
        .access-page section.location-info .googlemap-wrapper .googlemap-content iframe,
        .access-page section.location-info .googlemap-wrapper .googlemap-content object,
        .access-page section.location-info .googlemap-wrapper .googlemap-content embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
  /*
 SP operating-company 運営会社
---------------------------------------- */
  .operating-company-page .container {
    margin: 0; }
  .operating-company-page .company-info {
    margin: 30px auto 80px;
    padding: 40px 0 0 0;
    width: 100%;
    border-top: solid 2px #DBDCDC;
    position: relative;
    box-sizing: border-box; }
    .operating-company-page .company-info::before {
      content: ' ';
      position: absolute;
      top: -2px;
      left: calc(50% - 95px);
      width: 190px;
      height: 2px;
      background-color: #00913A; }
    .operating-company-page .company-info .company-info-table {
      display: block;
      width: 94%;
      height: auto;
      margin: 0 auto; }
      .operating-company-page .company-info .company-info-table tbody {
        width: 100%;
        height: auto; }
        .operating-company-page .company-info .company-info-table tbody tr {
          display: block;
          width: 100%;
          height: auto;
          padding: 10px 0;
          box-sizing: border-box;
          border-bottom: solid 1px #DBDCDC; }
          .operating-company-page .company-info .company-info-table tbody tr th {
            display: block;
            width: 100%;
            font-size: 15px;
            font-size: 1.5rem;
            font-weight: bold;
            border: none;
            padding: 0px 10px; }
          .operating-company-page .company-info .company-info-table tbody tr td {
            display: block;
            width: 100%;
            font-size: 14px;
            font-size: 1.4rem;
            padding: 0px 0 0 10px; }
  /*
 SP inquiry お問い合わせ
---------------------------------------- */
  .required {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 18px;
    background-color: #E50012;
    text-align: center;
    line-height: 15px;
    border-radius: 3px;
    margin: 0 5px; }
    .required::before {
      content: "必須";
      font-size: 12px;
      font-size: 1.2rem;
      color: #FFF; }
  .inquiry-page .container {
    margin: 0 0 50px 0; }
  .inquiry-page .inquiry-form-box {
    margin: 30px auto 0;
    width: 100%;
    height: auto;
    padding: 0 5%; }
    .inquiry-page .inquiry-form-box .inquiry-attention {
      width: 100%;
      height: auto;
      border: solid 1px #B5B5B6;
      padding: 15px 20px;
      box-sizing: border-box; }
      .inquiry-page .inquiry-form-box .inquiry-attention p {
        vertical-align: middle;
        font-size: 14px;
        font-size: 1.4rem;
        color: #333; }
    .inquiry-page .inquiry-form-box .inquiry-form {
      margin: 50px auto 0;
      width: 100%;
      height: auto; }
      .inquiry-page .inquiry-form-box .inquiry-form table {
        width: 100%; }
        .inquiry-page .inquiry-form-box .inquiry-form table tr {
          display: block;
          margin: 10px 0 0 0; }
        .inquiry-page .inquiry-form-box .inquiry-form table th {
          display: block;
          width: auto;
          height: auto;
          background-color: none;
          padding: 0;
          box-sizing: border-box;
          border: none;
          font-size: 14px;
          font-size: 1.4rem; }
        .inquiry-page .inquiry-form-box .inquiry-form table td {
          margin: 5px 0 0 0;
          display: block;
          padding: 0;
          box-sizing: border-box;
          border: none;
          font-size: 14px;
          font-size: 1.4rem; }
          .inquiry-page .inquiry-form-box .inquiry-form table td input,
          .inquiry-page .inquiry-form-box .inquiry-form table td textarea {
            width: 100%;
            height: 34px;
            border-radius: 5px;
            border: solid 1px #B5B5B6; }
          .inquiry-page .inquiry-form-box .inquiry-form table td textarea {
            height: 180px; }
      .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper {
        margin: 40px auto 0;
        width: 100%;
        height: auto; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information {
          margin: 15px 0 0 0;
          width: 100%;
          height: 250px;
          border: solid 1px #9E9E9F;
          padding: 10px 20px;
          box-sizing: border-box;
          overflow-y: scroll; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check {
          margin: 65px auto 0;
          width: 100%;
          height: auto;
          text-align: center;
          font-size: 0; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check input[type="checkbox"] {
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 4px;
            left: 0; }
            .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check input[type="checkbox"]:hover {
              cursor: pointer; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check label {
            width: 92%;
            display: inline-block;
            text-align: left;
            font-size: 14px;
            font-size: 1.4rem;
            color: #333; }
            .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .personal-information-check label:hover {
              cursor: pointer; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .inquiry-button {
          display: block;
          margin: 40px auto 0;
          font-size: 20px;
          font-size: 2rem; }
          .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .inquiry-button button:hover {
            cursor: pointer; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .about-holiday {
          margin: 70px 0 0 0;
          font-size: 14px;
          font-size: 1.4rem; }
        .inquiry-page .inquiry-form-box .inquiry-form .confirmation-wrapper .contact-info {
          margin: 50px 0 0 0;
          font-size: 14px;
          font-size: 1.4rem; }
  /*
 SP news 新着情報
---------------------------------------- */
  .news-page .container {
    margin: 0; }
  .news-page .news-section {
    margin: 30px auto 0;
    padding: 0 5%;
    display: block;
    width: 100%;
    height: auto; }
    .news-page .news-section .type-list {
      width: 100%;
      height: auto;
      border-bottom: solid 1px #9FA0A0;
      font-size: 0; }
      .news-page .news-section .type-list li {
        display: inline-block;
        vertical-align: bottom;
        font-size: 14px;
        font-size: 1.4rem;
        color: #333;
        margin: 8px;
        box-sizing: border-box; }
        .news-page .news-section .type-list li a {
          position: relative;
          width: auto;
          height: 100%;
          padding: 0 0 12px 0; }
          .news-page .news-section .type-list li a::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 3px;
            background-color: #009E41;
            transition: all .3s; }
          .news-page .news-section .type-list li a:hover {
            opacity: 1; }
            .news-page .news-section .type-list li a:hover::after {
              content: ' ';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: #009E41; }
        .news-page .news-section .type-list li.selected a::after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #009E41; }
    .news-page .news-section .news-list .news-item {
      border-bottom: solid 1px #9FA0A0;
      font-size: 0;
      padding: 15px 4%; }
      .news-page .news-section .news-list .news-item .type {
        display: inline-block;
        vertical-align: text-top;
        width: 115px;
        height: 31px;
        line-height: 31px;
        font-size: 13px;
        font-size: 1.3rem;
        color: #FFF;
        text-align: center;
        background-color: #81CCBE; }
        .news-page .news-section .news-list .news-item .type.update {
          background-color: #81CCBE; }
        .news-page .news-section .news-list .news-item .type.event {
          background-color: #F7A081; }
      .news-page .news-section .news-list .news-item .date {
        display: inline-block;
        vertical-align: text-top;
        font-size: 14px;
        font-size: 1.4rem;
        width: calc(100% - 135px);
        padding: 0;
        position: relative;
        line-height: 14px; }
        .news-page .news-section .news-list .news-item .date.is-new::after {
          display: block;
          content: "NEW!";
          position: absolute;
          bottom: -15px;
          left: 0;
          line-height: 1;
          color: #E33315;
          font-weight: bold; }
      .news-page .news-section .news-list .news-item .title {
        margin: 10px 0 0 0;
        display: inline-block;
        vertical-align: text-top;
        font-size: 14px;
        font-size: 1.4rem;
        width: 100%;
        padding: 0; }
    .news-page .news-section .pagination {
      margin: 30px 0 0 0;
      width: 100vw;
      height: auto;
      text-align: center; }
      .news-page .news-section .pagination .pages {
        display: inline-block;
        text-align: center;
        font-size: 0; }
        .news-page .news-section .pagination .pages a {
          display: inline-block;
          vertical-align: top;
          width: 15vw;
          height: 15vw;
          background-color: #FFF;
          border-bottom: solid 3px #ACA59B;
          font-size: 16.8px;
          font-size: 1.68rem;
          color: #CFC3AC;
          text-align: center;
          line-height: 15vw;
          cursor: pointer;
          margin: 0 0 0 4px;
          position: relative;
          top: 0;
          left: 0; }
          .news-page .news-section .pagination .pages a:first-child {
            margin: 0; }
          .news-page .news-section .pagination .pages a.current {
            background-color: #65655E;
            border-color: #464546;
            color: #FFF;
            cursor: default; }
            .news-page .news-section .pagination .pages a.current a {
              cursor: default;
              color: #FFF; }
          .news-page .news-section .pagination .pages a.prev {
            color: #9D9D9E;
            background-color: #F6F6F6; }
            .news-page .news-section .pagination .pages a.prev a {
              color: #9D9D9E; }
          .news-page .news-section .pagination .pages a.next {
            color: #9D9D9E;
            background-color: #F6F6F6; }
            .news-page .news-section .pagination .pages a.next a {
              color: #9D9D9E; }
  .news-detail-page .news-detail-section {
    margin: 30px auto 0;
    width: 100%;
    height: auto;
    padding: 0 5%; }
    .news-detail-page .news-detail-section .content {
      margin: 10px auto 0;
      width: 100%;
      height: auto;
      padding: 5%; }
    .news-detail-page .news-detail-section .back-to-list-button {
      margin: 40px auto 0;
      display: block;
      width: 175px;
      height: 175px;
      border-radius: 50%;
      background-color: #009E41;
      font-size: 24px;
      font-size: 2.4rem;
      color: #FFF;
      text-align: center;
      line-height: 175px; }
  /*
 SP personal-information 個人情報
---------------------------------------- */
  .personal-information-page .container {
    margin: 0; }
  .personal-information-page .about-personal-information {
    margin: 30px auto 0;
    width: 100%;
    padding: 0 5%; }
    .personal-information-page .about-personal-information .content-wrapper {
      width: 100%;
      height: auto;
      padding: 25px 0;
      box-sizing: border-box; }
      .personal-information-page .about-personal-information .content-wrapper .sub-title {
        margin: 40px 0 0 0;
        font-size: 14px;
        font-size: 1.4rem;
        color: #00913A; }
        .personal-information-page .about-personal-information .content-wrapper .sub-title:first-child {
          margin: 0; }
      .personal-information-page .about-personal-information .content-wrapper .content {
        font-size: 14px;
        font-size: 1.4rem;
        color: #333; }
    .personal-information-page .about-personal-information .privacy-mark-wrapper {
      margin: 30px 0 60px 0;
      width: 100%;
      height: auto;
      text-align: right;
      font-size: 0; }
      .personal-information-page .about-personal-information .privacy-mark-wrapper .privacy-mark-link {
        display: inline-block;
        vertical-align: top;
        width: 100px;
        height: auto; }
        .personal-information-page .about-personal-information .privacy-mark-wrapper .privacy-mark-link .privacy-mark-img {
          width: 100%;
          height: auto; }
      .personal-information-page .about-personal-information .privacy-mark-wrapper .description {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 110px);
        height: auto;
        text-align: left;
        margin: 0 0 0 10px;
        font-size: 14px;
        font-size: 1.4rem; } }

/*
 PC home
---------------------------------------- */

.home {
  .key {
    background: url(../img/bg_01.png) no-repeat center top;
    background-size: 100% auto;
    background-attachment: fixed;
    padding-bottom: 520px;
  }
  .copy {
    width: $widthBase;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    &_txt {
      padding-top: 40px;
      @include fs(34);
      font-family: $fontFamilyNotoSerif;
      line-height: 1.9;
    }
    &_img {
      padding-right: 34px;
    }
  }
  .thought {
    &_txt {
      text-align: center;
      padding-bottom: 60px;
    }
  }
  .features {
    padding-bottom: 20px;
    margin-top: -400px;
    &_inner {
      width: $widthMax;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;
    }
    &_cont {
      a {
        display: block;
        width: 350px;
        height: 350px;
        border-radius: 50%;
        background: $clrWht;
        border: 2px $clrGrn02 solid;
        text-align: center;
        position: relative;
        z-index: 1;
        &:hover {
          opacity: 1;
          border: 2px $clrGrn solid;
        }
      }
      .tit_en {
        font-family: $fontFamilyRaleway;
        font-weight: 100;
        padding: 70px 20px 0;
        @include fs(44);
        display: inline-block;
        position: relative;
        text-align: center;
        margin: 0 auto;
        line-height: 1.3;
        span {
          position: relative;
          z-index: 2;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          text-align: center;
          width: 105%;
          bottom: 0;
          left: -2.5%;
          z-index: 1;
          margin: 0 auto;
          height: 16px;
          background: $clrYlw02;
        }
      }
      .tit {
        @include fs(24);
        padding: 15px 0 5px;
      }
      .txt {
        width: 246px;
        margin: 0 auto;
        text-align: left;
        line-height: 1.8;
      }
    }
  }
  .curriculum {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 80%;
      margin: 0 -10%;
      background: -moz-linear-gradient(left, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: -webkit-linear-gradient(left, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: linear-gradient(to right, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3e0c11d', endColorstr='#b300913a', GradientType=1);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
    }
    &_wrap {
      padding: 170px 0;
      width: $widthMax;
      margin: 0 auto;
    }
    &_txt {
      width: 915px;
      margin: 0 auto;
    }
    &_inner {
      display: flex;
      justify-content: space-between;
      padding: 50px 0 60px;
    }
    &_cont {
      a {
        display: block;
        width: 264px;
        height: 264px;
        position: relative;
        .txt {
          width: 142px;
          height: 142px;
          background: rgba(0, 105, 52, 0.8);
          color: $clrWht;
          position: absolute;
          top: 50%;
          left: 50%;
          text-align: center;
          margin: -71px 0 0 -71px;
          padding-top: 20px;
          &_en {
            font-family: $fontFamilyRaleway;
            font-weight: 100;
            @include fs(29);
          }
          &_jp {
            @include fs(18);
          }
        }
      }
      &:first-child a {
        background: url(../img/index_img_03.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(2) a {
        background: url(../img/index_img_04.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(3) a {
        background: url(../img/index_img_05.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(4) a {
        background: url(../img/index_img_06.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
    }
    .btn_default {
      padding-bottom: 110px;
    }
  }
  .news {
    width: $widthBase;
    margin: -120px auto 0;
    .sec_tit {
      .jp {
        padding-bottom: 10px;
      }
    }
    .btn_min {
      margin: 0 0 20px auto;
    }
    &_inner {
      height: 380px;
      border: 1px $clrBorder solid;
      overflow-y: scroll;
      padding: 10px 25px;
      margin-bottom: 80px;
      a {
        display: block;
        &:hover {
          opacity: .6;
        }
      }
      .category {
        width: 126px;
        padding: 7px 0;
        text-align: center;
        @include fs(14);
        color: $clrWht;
        margin-top: 10px;
        float: left;
        &.update {
          background: #81ccbe;
        }
        &.event {
          background: #f7a081;
        }
      }
      li {
        border-bottom: 1px $clrBorder solid;
        padding: 20px 0;
      }
      .date {
        width: 130px;
        min-height: 38px;
        padding: 0 25px;
        float: left;
        .new {
          display: block;
          line-height: 1;
          font-weight: bold;
          color: $clrRed;
        }
      }
      .txt {
        min-height: 48px;
      }
    }
  }
}
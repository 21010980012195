// ----- mixin

// font-size
@mixin fs($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10) + rem;
}

// calc
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

// box-shadow
@mixin boxShadow($blur, $alpha) {
  box-shadow: 0 2px $blur 0 rgba(0, 0, 0, $alpha);
}

// opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
}


// border radius
@mixin borderRadius($size) {
  border-radius: $size;
}


// transition
@mixin trans($duration: .2s, $delay: 0s) {
  transition: all $duration ease-in-out $delay;
}

// transform
@mixin transform($transform) {
  transform: $transform;
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// rotate
@mixin rotate($rotate) {
  @include transform(rotate($rotate));
}


// placeholder
@mixin placeholdercolor($selection, $default, $focus) {
  &::selection,
  &::-moz-selection {
    color: $selection;
  }
  //&:placeholder-shown,
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $default;
    opacity: 1;
  }
  &:focus::-webkit-input-placeholder,
  &:focus:-moz-placeholder,
  &:focus:-ms-input-placeholder {
    color: $focus;
  }
}

@mixin selectNone {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// formreset
@mixin formreset {
  background-image: none;
  background-color: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// btn
@mixin btnAccent($clr, $darken) {
  background: $clr;
  color: $clrLink;
  &:hover {
    background: darken($clr, $darken); // color: $clrWht;
  }
}

// placeholder
@mixin placeholder($clr) {
  &::-webkit-input-placeholder {
    color: $clr;
  }
  &::-moz-placeholder {
    color: $clr;
  }
  &:-ms-input-placeholder {
    color: $clr;
  }
}

/*
 SP home
---------------------------------------- */

.home {
  .key {
    background: url(../img/bg_01_sp.png) no-repeat center top;
    background-size: cover;
    padding-bottom: 280px;
  }
  .copy {
    width: 92%;
    margin: 0 auto;
    padding-top: 15px;
    &_ribbon {
      width: 80%;
      padding-left: 4%;
    }
    &_txt {
      padding-top: 10px;
      @include fs(17);
      font-family: $fontFamilyNotoSerif;
      line-height: 1.9;
    }
    &_img {
      width: 55%;
      margin: 10px auto 0;
    }
  }
  .thought {
    width: 86%;
    margin: 0 auto;
    &_txt {
      text-align: center;
      padding-bottom: 60px;
    }
  }
  .features {
    padding-bottom: 30px;
    margin-top: -300px;
    &_inner {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    &_cont {
      a {
        display: block;
        width: 264px;
        height: 264px;
        border-radius: 50%;
        background: $clrWht;
        border: 2px $clrGrn02 solid;
        text-align: center;
        position: relative;
        z-index: 1;
        margin: 0 auto 20px;
      }
      .tit_en {
        font-family: $fontFamilyRaleway;
        font-weight: 100;
        padding: 50px 10px 0;
        @include fs(33);
        display: inline-block;
        position: relative;
        text-align: center;
        margin: 0 auto;
        line-height: 1.3;
        span {
          position: relative;
          z-index: 2;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          text-align: center;
          width: 100%;
          bottom: 0;
          left: 0;
          z-index: 1;
          margin: 0 auto;
          height: 12px;
          background: $clrYlw02;
        }
      }
      .tit {
        @include fs(18);
        padding: 7px 0 5px;
      }
      .txt {
        width: 180px;
        @include fs(12);
        margin: 0 auto;
        text-align: left;
        line-height: 1.8;
      }
    }
  }
  .curriculum {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 140%;
      height: 90%;
      margin: 0 -20%;
      background: -moz-linear-gradient(top, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: -webkit-linear-gradient(top, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      background: linear-gradient(to bottom, rgba(224, 193, 29, 0.3) 0%, rgba(0, 145, 58, 0.3) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3e0c11d', endColorstr='#b300913a', GradientType=1);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(-4deg);
      z-index: -1;
    }
    &_wrap {
      padding: 50px 0;
      width: 100%;
      margin: 0 auto;
    }
    &_txt {
      width: 92%;
      margin: 0 auto;
    }
    &_inner {
      padding: 25px 0 20px;
    }
    &_cont {
      margin: 0 auto 20px;
      a {
        margin: 0 auto;
        display: block;
        width: 200px;
        height: 200px;
        position: relative;
        .txt {
          width: 106px;
          height: 106px;
          background: rgba(0, 105, 52, 0.8);
          color: $clrWht;
          position: absolute;
          top: 50%;
          left: 50%;
          text-align: center;
          margin: -53px 0 0 -53px;
          padding-top: 15px;
          &_en {
            font-family: $fontFamilyRaleway;
            font-weight: 100;
            @include fs(22);
          }
          &_jp {
            @include fs(13);
          }
        }
      }
      &:first-child a {
        background: url(../img/index_img_03.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(2) a {
        background: url(../img/index_img_04.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(3) a {
        background: url(../img/index_img_05.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
      &:nth-of-type(4) a {
        background: url(../img/index_img_06.png) no-repeat center;
        background-size: 100%;
        &:hover {
          background-size: 110%;
          opacity: 1;
        }
      }
    }
    .btn_default {
      padding-bottom: 130px;
    }
  }
  .news {
    width: 92%;
    margin: -100px auto 0;
    .sec_tit {
      .jp {
        padding-bottom: 10px;
      }
    }
    .btn_min {
      margin: 0 0 20px auto;
    }
    &_inner {
      height: 380px;
      border: 1px $clrBorder solid;
      overflow-y: scroll;
      padding: 5px 6%;
      margin-bottom: 40px;
      a {
        display: block;
        &:hover {
          opacity: .6;
        }
      }
      .category {
        width: 115px;
        padding: 5px 0;
        text-align: center;
        @include fs(13);
        color: $clrWht;
        margin-top: 6px;
        display: inline-block;
        vertical-align: top;
        &.update {
          background: #81ccbe;
        }
        &.event {
          background: #f7a081;
        }
      }
      li {
        border-bottom: 1px $clrBorder solid;
        padding: 15px 0;
      }
      .date {
        width: 130px;
        min-height: 38px;
        padding: 0 5%;
        display: inline-block;
        vertical-align: top;
        .new {
          display: block;
          line-height: 1;
          font-weight: bold;
          color: $clrRed;
        }
      }
      .txt {
        padding-top: 8px;
      }
    }
  }
}
@charset "UTF-8";

@import "component/config";
@import "component/mixin";

@import "component/reset";
@import "component/base";
@import "component/common";

@media (min-width: 768px) {
	@import "parts/pc/pc-common";
  @import "parts/pc/pc-parts";
  @import "parts/pc/pc-index";
  @import "parts/pc/pc-features";
  @import "parts/pc/pc-curriculum";
  @import "parts/pc/pc-instructor";
  @import "parts/pc/pc-access";
  @import "parts/pc/pc-operating_company";
  @import "parts/pc/pc-inquiry";
  @import "parts/pc/pc-news";
  @import "parts/pc/pc-personal_information";
}

@media (max-width: 767px) {
	@import "parts/sp/sp-common";
  @import "parts/sp/sp-parts";
  @import "parts/sp/sp-index";
  @import "parts/sp/sp-features";
  @import "parts/sp/sp-curriculum";
  @import "parts/sp/sp-instructor";
  @import "parts/sp/sp-access";
  @import "parts/sp/sp-operating_company";
  @import "parts/sp/sp-inquiry";
  @import "parts/sp/sp-news";
  @import "parts/sp/sp-personal_information";
}
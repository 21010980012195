/*
  common
--------------------------------------------- */

.sp_only {
  @media #{$querySp} {
    display: block;
  }
  @media #{$queryPc} {
    display: none;
  }
}

.pc_only {
  @media #{$queryPc} {
    display: block;
  }
  @media #{$querySp} {
    display: none;
  }
}

/* float
______________________________________________*/

.fl_r {
	float: right;
}
.fl_l {
	float: left;
}


/* clearfix
______________________________________________*/

.clearfix:after {
	content: "";
	clear: both;
	display: block;
}

/* align
______________________________________________*/

.ta_l { text-align: left !important; }
.ta_c { text-align: center !important; }
.ta_r { text-align: right !important; }


.va_t { vertical-align: top !important; }
.va_m { vertical-align: middle !important; }
.va_b { vertical-align: bottom !important; }

/* margin
______________________________________________*/

$marginStride: 100;

@while $marginStride > -1 {
	.mt_#{$marginStride} {
		margin-top: #{$marginStride}px !important;
	}
	.mb_#{$marginStride} {
		margin-bottom: #{$marginStride}px !important;
	}
	.mr_#{$marginStride} {
		margin-right: #{$marginStride}px !important;
	}
	.ml_#{$marginStride} {
		margin-left: #{$marginStride}px !important;
	}
	$marginStride: $marginStride - 5;
}

/* Padding
______________________________________________*/

$paddingStride: 100;
@while $paddingStride > -1 {
	.pt_#{$paddingStride} {
		padding-top: #{$paddingStride}px !important;
	}
	.pb_#{$paddingStride} {
		padding-bottom: #{$paddingStride}px !important;
	}
	.pr_#{$paddingStride} {
		padding-right: #{$paddingStride}px !important;
	}
	.pl_#{$paddingStride} {
		padding-left: #{$paddingStride}px !important;
	}
	.p_#{$paddingStride} {
		padding: #{$paddingStride}px !important;
	}
	$paddingStride: $paddingStride - 5;
}

//SP
$paddingStrideSp: 100;
@while $paddingStrideSp > -1 {
	.pt_#{$paddingStrideSp}--sp {
		@media #{$querySp} {
			padding-top: #{$paddingStrideSp}px !important;
		}
	}
	.pb_#{$paddingStrideSp}--sp {
		@media #{$querySp} {
			padding-bottom: #{$paddingStrideSp}px !important;
		}
	}
	.pr_#{$paddingStrideSp}--sp {
		@media #{$querySp} {
			padding-right: #{$paddingStrideSp}px !important;
		}
	}
	.pl_#{$paddingStrideSp}--sp {
		@media #{$querySp} {
			padding-left: #{$paddingStrideSp}px !important;
		}
	}
	.p_#{$paddingStrideSp}--sp {
		@media #{$querySp} {
			padding: #{$paddingStrideSp}px !important;
		}
	}
	$paddingStrideSp: $paddingStrideSp - 5;
}

/* border
______________________________________________*/

.bdr_t {
  border-top: 1px $clrBorder solid;
}
.bdr_b {
  border-bottom: 1px $clrBorder solid;
}
.bdr_l {
  border-left: 1px $clrBorder solid;
}
.bdr_r {
  border-right: 1px $clrBorder solid;
}
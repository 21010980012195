/*
 SP common
---------------------------------------- */

/* ========== header ========== */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 43px;
  background: $clrWht;
  margin: 0 auto;
  padding: 10px 0;
  z-index: 999;
  &_main {
    padding: 0 4%;
    position: relative;
    top: -5px;
    left: 0;
  }
  &_logo {
    width: 160px;
  }
  &_btn {
    display: none;
  }
  &_nav {
    display: block;
    position: fixed;
    top: 43px;
    left: 100vw;
    width: 100vw;
    height: auto;
    background-color: #2E3641;
    opacity: 0;
    transition-duration: 0.3s;
    ul {
      width: 100%;
      height: auto;
      li {
        display: block;
        width: 100%;
        height: 45px;
        background-color: #2E3641;
        border: solid #DBDCDC;
        border-width: 1px 0 1px 0;
        margin: -1px 0 0 0;
        a {
          display: block;
          width: 100%;
          height: 45px;
          padding: 0 0 0 15px;
          box-sizing: border-box;
          line-height: 45px;
          color: #FFF;
        }
      }
    }
    &.isOpen {
      display: block;
      position: fixed;
      top: 43px;
      left: 0;
      opacity: 1;
      transition-duration: 0.3s;
    }
  }
  &_mobile-menu-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 43px;
    height: 43px;
    .mobile-menu-button {
      position: relative;
      width: 43px;
      height: 43px;
      background-color: #DBDCDC;
      border: none;
      padding: 0;
      .mobile-menu-button-border {
        position: absolute;
        top: 15px;
        left: 11.5px;
        width: 20px;
        height: 2px;
        background-color: #000;
        transition-duration: 0.3s;
        &.item1 {
          top: 15px;
        }
        &.item2 {
          top: 20px;
        }
        &.item3 {
          top: 25px;
        }
      }
      &.isOpen {
        background-color: #333;
        .mobile-menu-button-border {
          position: absolute;
          top: 15px;
          left: 11.5px;
          width: 20px;
          height: 2px;
          background-color: #000;
          transition-duration: 0.3s;
          &.item1 {
            top: 20px;
            background-color: #FFF;
            transition-duration: 0.3s;
            transform: rotate(-45deg);
          }
          &.item2 {
            top: 20px;
            background-color: #FFF;
            transition-duration: 0.3s;
            transform: rotate(45deg);
          }
          &.item3 {
            top: 20px;
            background-color: #FFF;
            transition-duration: 0.3s;
            transform: rotate(45deg);
          }
        }
      }
    }
    .mobile-menu-button {
      position: relative;
      width: 43px;
      height: 43px;
      background-color: #DBDCDC;
      border: none;
      padding: 0;
      .border1 {
        position: absolute;
        top: 15px;
        left: 11.5px;
        width: 20px;
        height: 2px;
        background-color: #000;
      }
      .border2 {
        position: absolute;
        top: 20px;
        left: 11.5px;
        width: 20px;
        height: 2px;
        background-color: #000;
      }
      .border3 {
        position: absolute;
        top: 25px;
        left: 11.5px;
        width: 20px;
        height: 2px;
        background-color: #000;
      }
    }
  }
}

/* ========== main ========== */

main {
  margin: 43px 0 0 0;
}

/* ========== footer ========== */

.footer {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    margin: 0 -10% 0;
    background: $clrGrn;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
    z-index: -1;
  }
  &_wrap {
    padding-top: 60px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  &_logo {
    padding-bottom: 40px;
    img {
      margin: 0 auto;
      text-align: center;
    }
  }
  &_nav {
    display: none;
  }
  &_txt {
    color: $clrWht;
    .copyright {
      padding: 40px 0 20px;
      @include fs(10);
    }
  }
  &_nav_sp {
    ul {
      margin-top: 30px;
      li {
        margin: 0 4% 10px;
        a {
          display: block;
          border: 1px $clrWht solid;
          background: #37a34a;
          text-align: center;
          color: $clrWht;
          line-height: 35px;
        }
      }
    }
    .contact_txt {
      text-align: center;
      padding: 10px 0;
    }
    .contact_btn {
      margin: 0 4%;
      a {
        display: block;
        border: 1px $clrWht solid;
        background: #37a34a;
        text-align: center;
        color: $clrWht;
        padding: 20px 0 10px;
        img {
          width: 10%;
          margin: 0 auto 5px;
        }
      }
    }
  }
  .page_top {
    width: 43px;
    cursor: pointer;
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 9999;
  }
}

.link_bot {
  position: relative;
  z-index: 2;
  ul {
    width: 100%;
    margin: 0 auto;
    li {
      width: 80%;
      margin: 0 auto 15px;
      a {
        border-radius: 5px;
        display: block;
        text-align: center;
        margin: 0 auto;
        @include fs(15);
        color: $clrWht;
        padding-bottom: 8px;
        img {
          width: 18%;
          text-align: center;
          margin: 0 auto;
          padding: 15px 0 5px;
        }
      }
    }
  }
  &_search a {
    background: $clrYlw;
    border-bottom: 4px $clrYlw03 solid;
  }
  &_login a {
    background: $clrGrn;
    border-bottom: 4px $clrGrn03 solid;
  }
  &_contact a {
    background: $clrOrg;
    border-bottom: 4px $clrOrg02 solid;
  }
}

.top {
  background: url(../img/bg_01.png) no-repeat center top;
  background-size: 100% auto;
  background-attachment: fixed;
  padding: 49px 0 43.5px 0;
  text-align: center;
  .top-title {
    display: inline-block;
    text-align: center;
    width: 80%;
    height: auto;
    .en {
      display: block;
      @include fs(20);
      color: #00913A;
      padding: 0;
      font-family: $fontFamilyRaleway;
      line-height: 1.4;
    }
    .jp {
      display: block;
      @include fs(13);
      color: #FFFFFF;
      line-height: 20px;
      background-color: #00913A;
    }
  }
}

.page-description {
  text-align: center;
  padding: 62.5px 5% 70px 5%;
  .content {
    width: 100%;
    height: auto;
    margin: 0 auto;
    .title {
      text-align: left;
      @include fs(17);
      color: #333;
    }
    hr {
      width: 32px;
      border-color: #333;
      border-width: 1px;
      margin: 25px auto;
    }
    .text {
      text-align: left;
      color: #333;
    }
  }
}

.pdf-download-button {
  display: block;
  width: 80%;
  height: auto;
  margin: 120px auto 15px;
  img {
    width: 100%;
    height: auto;
  }
}
/*
 PC curriculum カリキュラム
---------------------------------------- */

.curriculum-page {
	.container {
		margin: 0 0 70px 0;
		overflow: hidden;
	}
	.curriculum {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 1000px;
			left: 0;
			width: 120%;
			height: 75%;
			margin: 0 -10%;
			background: #e0c11d;
			background: -moz-linear-gradient(top, #e0c11d 0%, #00913a 100%);
			background: -webkit-linear-gradient(top, #e0c11d 0%, #00913a 100%);
			background: linear-gradient(to bottom, #e0c11d 0%, #00913a 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=0);
			-webkit-transform-origin: right center;
			-ms-transform-origin: right center;
			transform-origin: right center;
			-webkit-transform: rotate(-4deg);
			-ms-transform: rotate(-4deg);
			transform: rotate(-4deg);
			z-index: -1;
			opacity: 0.3;
		}
		.curriculum-graph-wrapper {
			display: block;
			width: 740px;
			height: 710px;
			margin: 0 auto;
		}
		.curriculum-list {
			margin: 210px auto 0;
			width: 1000px;
			height: auto;
			.curriculum-colmun {
				margin: 90px auto 0;
				width: 1000px;
				height: auto;
				&:first-child {
					margin: 0;
				}
				.content {
					margin: 95px 0 0 0;
					font-size: 0;
					img {
						display: inline-block;
						vertical-align: top;
						width: 450px;
						height: 280px;
					}
					p {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - 450px);
						@include fs(16);
						&.left-item {
							float: left;
							padding-right: 40px;
						}
						&.right-item {
							float: right;
							padding-left: 40px;
						}
					}
				}
			}
		}
	}
}
/* ========== tit ========== */

.min_tit {
  @include fs(17);
  text-align: center;
  padding-top: 15px;
  &:after {
    margin: 10px auto 20px;
    content: '';
    display: block;
    text-align: center;
    width: 24px;
    height: 1px;
    background: $clrBase;
  }
}

.sec_tit {
  text-align: center;
  .en {
    @include fs(54);
    font-weight: 100;
    font-family: $fontFamilyRaleway;
    display: block;
    line-height: 1.2;
    &:after {
      margin: 10px auto;
      content: '';
      display: block;
      text-align: center;
      width: 24px;
      height: 1px;
      background: $clrBase;
    }
  }
  .jp {
    @include fs(18);
    display: block;
    padding-bottom: 30px;
  }
  &.add-shape {
    background-image: url(../img/title-shape.png);
    background-repeat: no-repeat;
    background-size: 150px 165px;
    background-position: -10% 65%;
  }
}

.tit--green {
  // 緑の文字のタイトル
  text-align: center;
  .en {
    display: block;
    @include fs(42);
    color: #00913A;
    font-family: $fontFamilyRaleway;
    line-height: 1;
  }
  .jp {
    margin: 5px auto 0;
    display: block;
    @include fs(16);
    color: #FFF;
    background-color: #00913A;
    width: 190px;
    line-height: 20px;
    padding: 0 0 2px;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #00913A;
    position: relative;
    bottom: 11px;
    left: 0;
    z-index: -1;
  }
}

.tit--leftborder {
  width: 100%;
  height: auto;
  padding: 10px 5%;
  background-color: #F7F8F8;
  border-left: solid 6px #00913A;
  @include fs(16);
  color: #333;
  line-height: 1;
}

/* ========== btn ========== */

.btn_default {
  width: 70%;
  margin: 0 auto;
  a,
  button,
  input[type="submit"],
  input[type="button"]{
    display: block;
    background: $clrGrn url(../img/ico_arw.svg) no-repeat 90% center;
    border-radius: 5px;
    line-height: 37px;
    color: $clrWht;
    @include fs(15);
    font-family: $fontFamily;
    text-align: center;
    border: none;
    border-bottom: 3px $clrGrn03 solid;
    width: 100%;
  }
  button:disabled {
    background: $clrBorder url(../img/ico_arw.svg) no-repeat 90% center;
    border-bottom: 3px #888 solid;
    cursor: default;
  }
}

.btn_min {
  width: 40%;
  a {
    display: block;
    background: $clrGrn;
    color: $clrWht;
    padding: 2px 0;
    border-radius: 3px;
    text-align: center;
  }
}
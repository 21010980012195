/*
 PC news 新着情報
---------------------------------------- */

.news-page {
	.container {
		margin: 0 0 70px 0;
	}
	.news-section {
		margin: 100px auto 0;
		display: block;
		width: 1000px;
		height: auto;
		.type-list {
			width: 100%;
			height: auto;
			border-bottom: solid 1px #9FA0A0;
			font-size: 0;
			li {
				display: inline-block;
				vertical-align: bottom;
				@include fs(18);
				color: #333;
				margin: 0 40px;
				box-sizing: border-box;
				a {
					position: relative;
					width: auto;
					height: 100%;
					padding: 0 0 5px 0;
					&::after {
						content: ' ';
						position: absolute;
						bottom: 0;
						left: 50%;
						width: 0;
						height: 5px;
						background-color: #009E41;
						transition: all .3s;
					}
					&:hover {
						opacity: 1;
						&::after {
							content: ' ';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 5px;
							background-color: #009E41;
						}
					}
				}
				&.selected {
					a {
						&::after {
							content: ' ';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 5px;
							background-color: #009E41;
						}
					}
				}
			}
		}
		.news-list {
			.news-item {
				border-bottom: solid 1px $clrBorder;
				font-size: 0;
        padding: 20px 45px;
				.type {
					display: inline-block;
					vertical-align: text-top;
					width: 125px;
					height: 42px;
					line-height: 42px;
					@include fs(14);
					color: #FFF;
					text-align: center;
					background-color: #81CCBE;
          margin-top: 10px;
					&.update {
						background-color: #81CCBE;
					}
					&.event {
						background-color: #F7A081;
					}
				}
				.date {
					display: inline-block;
					vertical-align: text-top;
					@include fs(16);
					width: 100px;
					padding: 0 0 0 30px;
					position: relative;
					&.is-new {
						&::after {
							display: block;
							content: "NEW!";
							position: relative;
							color: #E33315;
							font-weight: bold;
              line-height: 1;
						}
					}
				}
				.title {
					display: inline-block;
					vertical-align: text-top;
					@include fs(16);
					width: 685px;
					padding: 0 0 0 25px;
				}
			}
		}
		.pagination {
			margin: 120px 0 0 0;
			width: 100%;
			height: auto;
			text-align: center;
			.pages {
				display: inline-block;
				text-align: center;
				font-size: 0;
				a {
					display: inline-block;
					vertical-align: top;
					width: 57px;
					height: 57px;
					background-color: #FFF;
					border-bottom: solid 3px #ACA59B;
					@include fs(16.8);
					color: #CFC3AC;
					text-align: center;
					line-height: 57px;
					cursor: pointer;
					position: relative;
					top: 0;
					left: 0;
					margin: 0 0 0 4px;
					&:first-child {
						margin: 0;
					}
					&.current {
						background-color: #65655E;
						border-color: #464546;
						color: #FFF;
						a {
							cursor: default;
							color: #FFF;
						}
					}
					&.prev {
						color: #9D9D9E;
						background-color: #F6F6F6;
						a {
							color: #9D9D9E;
						}
					}
					&.next {
						color: #9D9D9E;
						background-color: #F6F6F6;
						a {
							color: #9D9D9E;
						}
					}
				}
			}
		}
	}
}

.news-detail-page {
	.container {
		margin: 0 0 70px 0;
	}
	.news-detail-section {
		margin: 95px auto 0;
		width: 1000px;
		height: auto;
		.content {
			margin: 55px auto 0;
			width: 910px;
			height: auto;
		}
		.back-to-list-button {
			margin: 120px auto 0;
			display: block;
			width: 175px;
			height: 175px;
			border-radius: 50%;
			background-color: #009E41;
			@include fs(24);
			color: #FFF;
			text-align: center;
			line-height: 175px;
		}
	}
}
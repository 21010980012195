/*
 PC common
---------------------------------------- */

/* ========== header ========== */

.header {
  width: $widthBase;
  background: $clrWht;
  margin: 0 auto;
  padding-top: 10px;
  @include fs(14);
  &_main {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &_btn {
    ul {
      font-size: 0;
      li {
        a {
          &:hover {
            opacity: .6;
          }
        }
      }
    }
    &_search {
      display: inline-block;
      a {
        @include fs(14);
        display: block;
        width: 204px;
        line-height: 34px;
        color: $clrYlw;
        border: 1px $clrYlw solid;
        padding-left: 63px;
        background: url(../img/ico_search.svg) no-repeat 15px center;
      }
    }
    &_login {
      display: inline-block;
      margin-left: 20px;
      a {
        @include fs(14);
        display: block;
        width: 154px;
        line-height: 34px;
        color: $clrGrn;
        border: 1px $clrGrn solid;
        padding-left: 63px;
        background: url(../img/ico_login.svg) no-repeat 15px center;
      }
    }
  }
  &_nav {
    width: 812px;
    margin: 0 auto;
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      li {
        a {
          display: block;
          padding: 20px 0;
          color: $clrGry;
          &:hover {
            color: $clrGrn;
          }
        }
      }
    }
  }
}

/* ========== footer ========== */

.footer {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    margin: 0 -10% 0;
    background: $clrGrn;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
    z-index: -1;
  }
  &_wrap {
    padding-top: 170px;
    width: $widthMax;
    margin: 0 auto;
    @include fs(14);
    text-align: center;
  }
  &_logo {
    padding-bottom: 40px;
    img {
      margin: 0 auto;
      text-align: center;
    }
  }
  &_nav {
    padding-bottom: 40px;
    border-bottom: 1px $clrWht solid;
    ul {
      padding-bottom: 5px;
      li {
        display: inline-block;
        padding: 0 20px;
        a {
          color: $clrWht;
        }
      }
    }
  }
  &_txt {
    color: $clrWht;
    padding-top: 20px;
    .copyright {
      padding: 20px 0;
      @include fs(12);
    }
  }
  .footer_nav_sp{
    display: none;
  }
  .page_top {
    cursor: pointer;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 9999;
  }

}

.link_bot {
  position: relative;
  z-index: 2;
  ul {
    width: 968px;
    margin: 0 auto -120px;
    display: flex;
    justify-content: space-between;
    li {
      width: 286px;
      background: #fff;
      border-radius: 5px;
      a {
        border-radius: 5px;
        display: block;
        text-align: center;
        margin: 0 auto;
        @include fs(17);
        color: $clrWht;
        padding-bottom: 10px;
        img {
          text-align: center;
          margin: 0 auto;
          padding: 20px 0 10px;
        }
      }
    }
  }
  &_search a {
    background: $clrYlw;
    border-bottom: 4px $clrYlw03 solid;
  }
  &_login a {
    background: $clrGrn;
    border-bottom: 4px $clrGrn03 solid;
  }
  &_contact a {
    background: $clrOrg;
    border-bottom: 4px $clrOrg02 solid;
  }
}

.top {
  background: url(../img/bg_01.png) no-repeat center top;
  background-size: 100% auto;
  background-attachment: fixed;
  padding: 85px 0 95px 0;
  text-align: center;
  .top-title {
    display: inline-block;
    text-align: center;
    width: auto;
    .en {
      display: block;
      @include fs(44);
      color: $clrGrn;
      font-family: $fontFamilyRaleway;
      font-weight: 100;
      line-height: 1.3;
      padding: 0 20px;
    }
    .jp {
      display: block;
      @include fs(18);
      color: #FFFFFF;
      width: 100%;
      line-height: 23px;
      background-color: $clrGrn;
      padding: 0;
    }
  }
}

.page-description {
  text-align: center;
  padding: 90px 0 70px 0;
  .content {
    width: 736px;
    height: auto;
    margin: 0 auto;
    .title {
      text-align: left;
      @include fs(28);
      color: #333;
    }
    hr {
      width: 32px;
      border-color: #333;
      border-width: 1px;
      margin: 25px auto;
    }
    .text {
      text-align: left;
      @include fs(16);
      color: #333;
    }
  }
}

.pdf-download-button {
  display: block;
  width: 750px;
  height: 230px;
  margin: 160px auto 0;
  img {
    width: 750px;
    height: 230px;
  }
}
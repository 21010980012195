/*
 PC inquiry お問い合わせ
---------------------------------------- */

.required {
	display: inline-block;
	width: 60px;
	height: 20px;
	background-color: #E50012;
	text-align: center;
	line-height: 20px;
	border-radius: 3px;
	margin: 0 10px;
	&::before {
		content: "必須";
		@include fs(16);
		color: #FFF;
	}
}

.inquiry-page {
	.container {
		margin: 0 0 50px 0;
	}
	.inquiry-form-box {
		margin: 90px auto 0;
		width: 1130px;
		height: auto;
		.inquiry-attention {
			width: 100%;
			height: auto;
			border: solid 1px #B5B5B6;
			padding: 30px 115px;
			box-sizing: border-box;
			p {
				vertical-align: middle;
				@include fs(16);
				color: #333;
			}
		}
		.inquiry-form {
			margin: 50px auto 0;
			width: 1130px;
			height: auto;
			table {
				width: 1130px;
				th {
					width: 280px;
					height: auto;
					background-color: #F7F8F8;
					padding: 30px 25px;
					box-sizing: border-box;
					border: solid 1px #B5B5B6;
				}
				td {
					input,
					textarea {
						width: 100%;
						height: 34px;
						border-radius: 5px;
						border: solid 1px #B5B5B6;
					}
					textarea {
						height: 180px;
					}
					padding: 25px 50px;
					box-sizing: border-box;
					border: solid 1px #B5B5B6;
				}
			}
			.confirmation-wrapper {
				margin: 40px auto 0;
				width: 975px;
				height: auto;
				.personal-information {
					margin: 15px 0 0 0;
					width: 100%;
					height: 290px;
					border: solid 1px #9E9E9F;
					padding: 10px 20px;
					box-sizing: border-box;
					overflow-y: scroll;
				}
				.personal-information-check {
					margin: 65px auto 0;
					width: 100%;
					height: auto;
					text-align: center;
					font-size: 0;
					input[type="checkbox"] {
						display: inline-block;
						vertical-align: top;
						position: relative;
						top: 7px;
						left: 0;
						&:hover{
							cursor: pointer;
						}
					}
					label {
						display: inline-block;
						vertical-align: top;
						text-align: left;
						@include fs(16);
						color: #333;
						&:hover{
							cursor: pointer;
						}
					}
				}
				.inquiry-button {
					margin: 40px auto 0;
					button:hover{
						cursor: pointer;
					}
				}
				.about-holiday {
					margin: 70px 0 0 0;
					@include fs(16);
				}
				.contact-info {
					margin: 50px 0 0 0;
					@include fs(16);
				}
			}
		}
	}
}
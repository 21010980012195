/*
 PC instructor 講師紹介
---------------------------------------- */

.instructor-page {
	.container {
		margin: 0 0 70px 0;
	}
	.instructor {
		padding: 0 0 75px 0;
		position: relative;
		display: block;
		.instructor-list {
			width: 830px;
			height: auto;
			margin: 60px auto 0;
			.instructor-item {
				display: block;
				width: 830px;
				height: auto;
				padding: 55px 48px 58px 66px;
				box-sizing: border-box;
				font-size: 0;
				margin: 50px 0 0 0;
				background-color: #fff;
				position: relative;
				&::after {
					content: '\00a0';
					display: block;
					position: absolute;
					top: -3px;
					left: -3px;
					z-index: -1;
					width: calc(100% + 6px);
					height: calc(100% + 6px);
					background: #e0c11d;
					background: -moz-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
					background: -webkit-linear-gradient(-45deg, #e0c11d 0%, #00913a 100%);
					background: linear-gradient(135deg, #e0c11d 0%, #00913a 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0c11d', endColorstr='#00913a', GradientType=1);
				}
				&:first-child {
					margin: 0;
				}
				.instructor-image-wrapper {
					display: inline-block;
					vertical-align: top;
					width: 240px;
					height: auto;
					text-align: center;
					img {
						display: inline-block;
						vertical-align: top;
						&.large {
							width: 240px;
						}
						&.small {
							width: 150px;
						}
					}
				}
				.instructor-text-wrapper {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - 240px);
					padding: 30px 0 0 40px;
					text-align: left;
					.instructor-name {
						@include fs(24);
						color: #333;
						width: 100%;
						border: solid #333;
						border-width: 0 0 1px 0;
					}
					.instructor-affiliation {
						margin: 15px 0 0 0;
						@include fs(16);
						color: #00913A;
					}
					.instructor-field {
						margin: 25px 0 0 0;
						@include fs(16);
						color: #333;
					}
					.instructor-description {
						margin: 20px 0 0 0;
						@include fs(14);
						color: #333;
					}
				}
			}
		}
	}
	.pdf-download-button {
		margin: 80px auto 0;
	}
}
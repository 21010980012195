/*
 SP operating-company 運営会社
---------------------------------------- */

.operating-company-page {
	.container {
		margin: 0;
	}
	.company-info {
		margin: 30px auto 80px;
		padding: 40px 0 0 0;
		width: 100%;
		border-top: solid 2px #DBDCDC;
		position: relative;
		box-sizing: border-box;
		&::before {
			content: ' ';
			position: absolute;
			top: -2px;
			left: calc(50% - 95px);
			width: 190px;
			height: 2px;
			background-color: #00913A;
		}
		.company-info-table {
			display: block; // width: 810px;
			width: 94%;
			height: auto;
			margin: 0 auto;
			tbody {
				width: 100%;
				height: auto;
				tr {
					display: block;
					width: 100%;
					height: auto;
					padding: 10px 0;
					box-sizing: border-box;
					border-bottom: solid 1px #DBDCDC;
					th {
            display: block;
            width: 100%;
						@include fs(15);
						font-weight: bold;
						border: none;
						padding: 0px 10px;
					}
					td {
            display: block;
            width: 100%;
						@include fs(14);
						padding: 0px 0 0 10px;
					}
				}
			}
		}
	}
}